import tableService from '../../../../services/tableService';
import dataTransform from '../../../../util/dataTransform';
import { message, Spin } from 'antd';
import Query from '../../../../util/query';

export const IS_TABLE_LOADING = 'IS_TABLE_LOADING';
export const ONE_STUDENT_FEEDBACK_LIST = 'ONE_STUDENT_FEEDBACK_LIST';
export const IS_DELETE_FEEDBACK = 'IS_DELETE_FEEDBACK';
export const ENROLLMENT_LIST = 'ENROLLMENT_LIST';
export const ATTENDANCE_LIST = 'ATTENDANCE_LIST';
export const ATTENDANCE_TOTAL = 'ATTENDANCE_TOTAL';
export const ENROLLMENT_LIST_TOTAL = 'ENROLLMENT_LIST_TOTAL';
export const ENROLLMENT_LIST_STATISTIC = 'ENROLLMENT_LIST_STATISTIC';
export const TRANSACTION_LIST_STATIC = 'TRANSACTION_LIST_STATIC';
export const ENROLLMENT_TABLE_QUERY = 'ENROLLMENT_TABLE_QUERY';
export const FEEDBACK_DETAIL_LIST = 'FEEDBACK_DETAIL_LIST';
export const FEEDBACK_DETAIL_TOTAL = 'FEEDBACK_DETAIL_TOTAL';
export const TRANSACTION_SUMMARY_LIST = 'TRANSACTION_SUMMARY_LIST';
export const TRANSACTION_SUMMARY_TOTAL = 'TRANSACTION_SUMMARY_TOTAL';

export function fetchOneStudentFeedbackList(enrollmentId) {
  return async (dispatch) => {
    console.log('fetchFeedbackList in actions');
    dispatch(isTableLoading(true));
    const res = await tableService.searchFeedbacks(enrollmentId);
    if (res.data) {
      console.log('获取 feedback 数据: ', res.data);
      dispatch(feedbackDataSource(res.data));
      dispatch(isTableLoading(false));
    }
  };
}

export function deleteFeedback(feedbackId) {
  return async (dispatch) => {
    console.log('delete feedback');
    try {
      const res = await tableService.deleteFeedbacks(feedbackId);
      if (res.data) {
        console.log('删除成功');
        dispatch(isFeedbackDelete(true));
      }
    } catch (error) {
      console.log('error: ', error);
      message.error(error.response.data.message);
    }
  };
}

export function fetchEnrollments(page, per_page, query) {
  return async (dispatch) => {
    console.log('fetchEnrollments page & per_page & query');
    dispatch(isTableLoading(true));
    try {
      const res = await tableService.getEnrollments(page, per_page, query);
      if (res.data) {
        // console.log('获取enrolment list: ', res.data);
        const {
          total,
          displayData,
          totalEnrollPrice,
          totalBalance,
        } = dataTransform.enrollmentListDataTransform(res.data);
        dispatch(enrollmentListData(displayData));
        dispatch(enrollmentStatistic({ totalEnrollPrice, totalBalance }));
        dispatch(enrollmentTotal(total));
        dispatch(isTableLoading(false));
      }
    } catch (error) {
      message.error('Error in fetch Enrollment');
    }
  };
}

export function fetchAttendances(page, per_page, query) {
  return async (dispatch) => {
    console.log('fetchAttendances page & per_page & query');
    dispatch(isTableLoading(true));
    const res = await tableService.getAttendances(page, per_page, query);
    try {
      if (res.data) {
        console.log('获取attendance list: ', res.data);
        const {
          total,
          displayData,
        } = dataTransform.attendanceListDataTransform(res.data);
        dispatch(attendanceListData(displayData));
        dispatch(attendanceTotal(total));
        dispatch(isTableLoading(false));
      }
    } catch (error) {
      message.error('Error in fetch Attendance');
    }
  };
}

export function fetchFeedbackDetails(page, per_page, query) {
  return async (dispatch) => {
    dispatch(isTableLoading(true));
    console.log('fetchFeedbackDetails page & per_page & query');
    try {
      const res = await tableService.getFeedbackDetails(page, per_page, query);
      if (res.data) {
        const {
          total,
          displayData,
        } = dataTransform.feedbackDetailDataTransform(res.data);
        dispatch(feedbackDetailsData(displayData));
        dispatch(feedbackDetailTotal(total));
        dispatch(isTableLoading(false));
      }
    } catch (error) {
      message.error('Error in fetch Feedback');
    }
  };
}

export function fetchTransactionSummary(page, per_page, query) {
  return async (dispatch) => {
    console.log('fetchTransactionSummary');
    dispatch(isTableLoading(true));
    try {
      let res = await tableService.getTransactionSummary(page, per_page, query);
      if (res.data) {
        const {
          total,
          totalTransaction,
          displayData,
        } = dataTransform.transactionSummaryDataTransform(res.data);
        console.log('transaction summary data: ', displayData);
        dispatch(transactionSummaryData(displayData));
        dispatch(transactionSummaryTotal(total));
        dispatch(transactionStatistic(totalTransaction));
        dispatch(isTableLoading(false));
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
}

export function getQueryForFeedbackDetail(FieldsValue, courseList) {
  console.log('getQueryForFeedbackDetail FieldsValue is: ', FieldsValue);
  let { student_id, teacher, course, courseType, rangeDatePick } = FieldsValue;
  if (course) {
    course = Query.mapCourseNameById(course, courseList);
  }
  let query = Query.constructQueryForFeedbackDetail({
    student_id,
    teacher,
    course,
    courseType,
    rangeDatePick,
  });
  return query;
}

export function isTableLoading(data) {
  return {
    type: IS_TABLE_LOADING,
    payload: data,
  };
}

export function transactionSummaryData(data) {
  return {
    type: TRANSACTION_SUMMARY_LIST,
    payload: data,
  };
}

export function transactionSummaryTotal(data) {
  return {
    type: TRANSACTION_SUMMARY_TOTAL,
    payload: data,
  };
}

export function feedbackDataSource(data) {
  return {
    type: ONE_STUDENT_FEEDBACK_LIST,
    payload: data,
  };
}

export function isFeedbackDelete(data) {
  return {
    type: IS_DELETE_FEEDBACK,
    payload: data,
  };
}

export function enrollmentStatistic(data) {
  return {
    type: ENROLLMENT_LIST_STATISTIC,
    payload: data,
  };
}

export function transactionStatistic(data) {
  return {
    type: TRANSACTION_LIST_STATIC,
    payload: data,
  };
}

export function enrollmentTotal(data) {
  return {
    type: ENROLLMENT_LIST_TOTAL,
    payload: data,
  };
}

export function attendanceTotal(data) {
  return {
    type: ATTENDANCE_TOTAL,
    payload: data,
  };
}

export function enrollmentListData(data) {
  return {
    type: ENROLLMENT_LIST,
    payload: data,
  };
}

export function attendanceListData(data) {
  return {
    type: ATTENDANCE_LIST,
    payload: data,
  };
}

export function feedbackDetailsData(data) {
  return {
    type: FEEDBACK_DETAIL_LIST,
    payload: data,
  };
}

export function feedbackDetailTotal(data) {
  return {
    type: FEEDBACK_DETAIL_TOTAL,
    payload: data,
  };
}

export function enrollmentTableQuery(data) {
  return {
    type: ENROLLMENT_TABLE_QUERY,
    payload: data,
  };
}
