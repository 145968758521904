import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from '../components/routers/ProtectedRoute';
import LandingPage from './UserAccess/LandingPage';
import Home from './home/Home';
import App from './App';
import Admin from './Admin';
import Todos from './todos';
import EnrollStudentPage from './form/EnrollStudentPage';
import EnrollCoursePage from './form/EnrollCoursePage';
import TransactionPage from './form/TransactionPage';
import FeedbackPage from './form/FeedbackPage';
import FormDone from './form/FormDone';
import EnrollmentTablePage from './table/EnrollmentTablePage';
import TransactionTablePage from './table/TransactionTablePage';
import FeedbackTablePage from './table/FeedbackTablePage';
import AttendanceTablePage from './table/AttendanceTablePage';
import CompanyChartsPage from './charts/CompanyChartsPage';
import TeacherChartsPage from './charts/TeacherChartsPage';
import localStorageService from '../../services/localStorageService';
import StudentPage from './student/StudentPage';
import Logout from './UserAccess/logout/logout';

const IRouter = () => {
  return (
    <BrowserRouter>
      <App>
        <Switch>
          <Route
            path='/'
            render={() => (
              <Admin>
                <Switch>
                  <Route exact path='/' component={LandingPage} />
                  <ProtectedRoute
                    exact
                    path='/home'
                    component={Home}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/form/student'
                    component={EnrollStudentPage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/form/course'
                    component={EnrollCoursePage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/form/transaction'
                    component={TransactionPage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/form/feedback'
                    component={FeedbackPage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/form/todolist'
                    component={Todos}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/form/done'
                    component={FormDone}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/table/enrollment'
                    component={EnrollmentTablePage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/table/transaction'
                    component={TransactionTablePage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/table/feedback'
                    component={FeedbackTablePage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/table/attendance'
                    component={AttendanceTablePage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/charts/company'
                    component={CompanyChartsPage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    exact
                    path='/charts/teacher'
                    component={TeacherChartsPage}
                    authed={localStorageService.checkJWT()}
                  />
                  <ProtectedRoute
                    path='/student/:id'
                    component={StudentPage}
                    authed={localStorageService.checkJWT()}
                  ></ProtectedRoute>
                  <Route exact path='/logout/done' component={Logout} />
                </Switch>
              </Admin>
            )}
          />
        </Switch>
      </App>
    </BrowserRouter>
  );
};

export default IRouter;
