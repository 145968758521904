import React from 'react';
import { Card, Col, Row } from 'antd';
import CardsWrapper from '../../components/charts/cards/CardsWrapper';
import SalesCategoryPie from '../../components/charts/SalesCategoryPie';
import AttendanceBar from '../../components/charts/AttendanceBar';
import Mock from '../../../Mock/pies/category';

const cardStyle = { margin: '12px' };

const CompanyChartsPage = () => {
  return (
    <>
      <Row>
        <CardsWrapper
          salesNum='$36890'
          subSalesNum='10'
          trialNum='95%'
          subTrialNum='10'
          attendanceNum='88'
          subAttendanceNum='10'
          balanceNum='$5200'
          subBalanceNum='-10'
        />
      </Row>
      <Row>
        <Col xl={12} xs={24}>
          <Card
            bordered={true}
            style={cardStyle}
            hoverable
            title='本周学生签到图'
          >
            <AttendanceBar
              data={{
                x: Mock.weekData,
                legend: Mock.teacherLegendData,
                data: Mock.attendanceBarData,
              }}
            />
          </Card>
        </Col>
        <Col xl={12} xs={24}>
          <Card bordered={true} style={cardStyle} hoverable title='每月营收图'>
            <AttendanceBar
              data={{
                x: Mock.monthData,
                legend: Mock.salesLegendData,
                data: Mock.salesBarData,
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={12} xs={24}>
          <Card
            bordered={true}
            style={cardStyle}
            hoverable
            title='课程销售占比'
          >
            <SalesCategoryPie
              data={{
                name: Mock.courseName,
                category: Mock.courseCategory,
                data: Mock.saleCourse,
              }}
            />
          </Card>
        </Col>
        <Col xl={12} xs={24}>
          <Card
            bordered={true}
            style={cardStyle}
            hoverable
            title='上课课程占比'
          >
            <SalesCategoryPie
              data={{
                name: Mock.courseName,
                category: Mock.courseCategory,
                data: Mock.feedbackCourse,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CompanyChartsPage;
