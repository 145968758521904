import React from 'react';
import { Result } from 'antd';

const Logout = () => {
  return (
    <div>
      <Result status='success' title={'Logout success'} />
    </div>
  );
};

export default Logout;
