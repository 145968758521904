import React, { useEffect } from 'react';
import { Card, Table } from 'antd';
import { useParams } from 'react-router-dom';

const Enrolls = (props) => {
  const { fetchEnrollCourseList, enrollList, columns } = props;
  const studentId = useParams().id;

  useEffect(() => {
    fetchEnrollCourseList(studentId);
  }, []);

  return (
    <Card>
      <Table dataSource={enrollList} columns={columns} />
    </Card>
  );
};

export default Enrolls;
