import React from 'react';
import { Form, Select } from 'antd';
import Layout from './layout';

const FormItem = Form.Item;
const Option = Select.Option;

const defaultRules = [
  {
    required: true,
    message: '课程不能为空',
  },
];

const CourseFormItem = (props) => {
  const { fetchCoursesList, courseList, itemStyle, form } = props;
  let layouts = props.formItemLayout
    ? props.formItemLayout
    : Layout.formItemLayout;
  let rules = props.rules ? props.rules : defaultRules;

  const courses = courseList.sort((a, b) => a.code - b.code);

  return (
    <FormItem label='课程' {...layouts} name='course' rules={rules} form={form}>
      {
        <Select
          mode='single'
          onFocus={fetchCoursesList}
          style={itemStyle}
          allowClear
        >
          {courses.map((item, i) => {
            return (
              <Option value={item._id} key={item._id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      }
    </FormItem>
  );
};

export default CourseFormItem;
