import React from 'react';
import { Card, Col, Row, Statistic, Divider } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import SalesCard from './SalesCard';

const CardsWrapper = (props) => {
  const {
    salesNum,
    subSalesNum,
    trialNum,
    subTrialNum,
    attendanceNum,
    subAttendanceNum,
    balanceNum,
    subBalanceNum,
  } = props;
  return (
    <>
      <Col xl={6} md={12} xs={24}>
        <SalesCard
          title='月销售总额'
          value={salesNum}
          subTitle='上个月同比:'
          subValue={subSalesNum}
        />
      </Col>
      <Col xl={6} md={12} xs={24}>
        <SalesCard
          title='30天试听课转化率'
          value={trialNum}
          subTitle='上个月同比:'
          subValue={subTrialNum}
        />
      </Col>
      <Col xl={6} md={12} xs={24}>
        <SalesCard
          title='30天累计出勤人数'
          value={attendanceNum}
          subTitle='上个月同比:'
          subValue={subAttendanceNum}
        />
      </Col>
      <Col xl={6} md={12} xs={24}>
        <SalesCard
          title='300天尾款余额'
          value={balanceNum}
          subTitle='上个90天同比:'
          subValue={subBalanceNum}
        />
      </Col>
    </>
  );
};

export default CardsWrapper;
