import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import './index.less';
import menuConfig from '../../../../config/menuConfig';
import menuRoleTwo from '../../../../config/menuRoleTwo';
import LocalStorageService from '../../../../services/localStorageService';
import { setMenu } from '../../../containers/containerStore/pageAction';

const { SubMenu } = Menu;

const NavLeft = () => {
  const dispatch = useDispatch();
  // console.log('userRole', userRole);
  const [menTreeNode, setMenTreeNode] = useState(renderMenu(menuRoleTwo));

  useEffect(() => {
    let menu =
      LocalStorageService.getCurrentRole() === 0 ||
      LocalStorageService.getCurrentRole() === 1
        ? menuConfig
        : menuRoleTwo;
    // console.log('reset menu to: ', menu);
    setMenTreeNode(renderMenu(menu));
  }, [LocalStorageService.getCurrentRole()]);

  function switchMenu(item) {
    console.log('switchMenu', dispatch(setMenu(item)));
  }

  return (
    <div className='nav'>
      <div className='logo'>
        <h1>PA Data</h1>
      </div>
      <Menu
        className='menu'
        onClick={({ item }) => switchMenu(item.props.title)}
      >
        {menTreeNode}
      </Menu>
    </div>
  );
};

const renderMenu = (data, collapsed) => {
  return data.map((item) => {
    if (item.children) {
      return (
        <SubMenu
          title={
            <span>
              {item.icon}
              <span>{item.title}</span>
            </span>
          }
          key={item.key}
        >
          {renderMenu(item.children)}
        </SubMenu>
      );
    }
    return (
      <Menu.Item title={item.title} key={item.key}>
        <NavLink to={item.key} className='nav-item'>
          <span>
            {item.icon}
            <span>{item.title}</span>
          </span>
        </NavLink>
      </Menu.Item>
    );
  });
};

export default NavLeft;
