import React from 'react';

const TodoItem = (props) => {
  const {
    todo: { id, text, complete },
    removeTodo,
    toggleTodo,
  } = props;

  const handleRemove = () => {
    removeTodo(id);
  };
  const handleToggle = () => {
    toggleTodo(id);
  };

  return (
    <li>
      <input type='checkbox' checked={complete} onChange={handleToggle} />
      {text}
      <button onClick={handleRemove}>remove</button>
    </li>
  );
};

export default TodoItem;
