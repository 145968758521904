import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoginApp from './login/Login';
import { requestLogin } from '../containerStore/pageAction';
import localStorageService from '../../../services/localStorageService';

export const LandingPage = (props) => {
  console.log('Landing page state is: ', props);
  const dispatch = useDispatch();
  const { history } = props;
  const isJWTExist = localStorageService.checkJWT();
  // console.log('dispatch in LandingPage: ', dispatch);

  const loginCbs = useMemo(() => {
    return bindActionCreators(
      {
        requestLogin,
      },
      dispatch
    );
  }, []);

  return isJWTExist ? (
    <Redirect to='/home' />
  ) : (
    <div>
      <LoginApp history={history} {...loginCbs} />
    </div>
  );
};

export default LandingPage;
