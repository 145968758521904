import React from 'react';
import TodoItem from './TodoItem';

const ShowTodos = (props) => {
  const { todos, removeTodo, toggleTodo } = props;
  console.log('show todos: ', todos);

  return (
    <ul>
      {todos.map((todo) => {
        return (
          <TodoItem
            key={todo.id}
            todo={todo}
            removeTodo={removeTodo}
            toggleTodo={toggleTodo}
          />
        );
      })}
    </ul>
  );
};

export default ShowTodos;
