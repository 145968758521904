import React, { useState } from 'react';
import { Form, Button, Radio } from 'antd';

import Layout from './layout';
import Global from '../../../util/global';
import StudentFormItem from './StudentFormItem';
import TeacherFormItem from './TeacherFormItem';
import AdvisorFormItem from './AdvisorFormItem';
import RangeDateFormItem from './RangeDateFormItem';
import BrForForm from './BrForForm';

/**
 * 目前这个component在enrollment and attendance都被用到
 */
const FilterFormAttendance = (props) => {
  const {
    fetchStudentList,
    studentList,
    teacherList,
    fetchTeacherList,
    advisorList,
    fetchAdvisorList,
    search,
    pagination,
    defaultInitialValue,
    form,
    getQuery,
  } = props;

  const [radioValue, setRadioValue] = useState(Global.today);

  /**
   * The request
   */
  function query() {
    let query = getQuery();
    search(pagination.current, pagination.pageSize, query);
    console.log('query', form.getFieldValue());
  }

  function handleSizeChange(e) {
    console.log('handleSizeChange: ', e);
    form.setFieldsValue({ rangeDatePick: e.target.value });
    setRadioValue(e.target.value);
    let query = getQuery();
    search(pagination.current, pagination.pageSize, query);
  }

  function onReset() {
    console.log('reset: ', form.getFieldValue());
    form.resetFields();
    form.setFieldsValue({ rangeDatePick: [] });
    setRadioValue(null);
  }

  return (
    <Form
      layout='inline'
      form={form}
      initialValues={{ rangeDatePick: Global.today }}
    >
      <StudentFormItem
        formItemLayout={{}}
        rules={[]}
        form={form}
        fetchStudentList={fetchStudentList}
        studentList={studentList}
        autoCompleteStyle={Layout.autoCompleteStyle}
      />
      <TeacherFormItem
        formItemLayout={{}}
        rules={[]}
        form={form}
        teacherList={teacherList}
        fetchTeacherList={fetchTeacherList}
        itemStyle={Layout.filterInputStyle}
      />
      <AdvisorFormItem
        formItemLayout={{}}
        rules={[]}
        form={form}
        advisorList={advisorList}
        fetchAdvisorList={fetchAdvisorList}
        itemStyle={Layout.filterInputStyle}
      />
      <BrForForm />
      <RangeDateFormItem form={form} setRadioValue={setRadioValue} />
      <BrForForm />
      <Form.Item>
        <Radio.Group value={radioValue} onChange={(e) => handleSizeChange(e)}>
          <Radio.Button value={Global.today}>今日</Radio.Button>
          <Radio.Button value={Global.thisMonth}>本月</Radio.Button>
          <Radio.Button value={Global.last90}>季度</Radio.Button>
          <Radio.Button value={Global.all}>全部</Radio.Button>
        </Radio.Group>
        <Button type='primary' style={{ margin: '0 20px' }} onClick={query}>
          查询
        </Button>
        <Button htmlType='button' onClick={onReset}>
          重置
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FilterFormAttendance;
