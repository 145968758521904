import React from 'react';
import { Form, Select, Input } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;

const RangeNumberFormItem = (props) => {
  const { form } = props;
  return (
    <>
      <FormItem>
        <Input.Group compact>
          <FormItem label='出勤次数' name='numFrom' form={form}>
            <Input
              style={{ width: 100, textAlign: 'center' }}
              placeholder='Min'
            />
          </FormItem>
          <Input
            style={{
              width: 30,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            placeholder='~'
            disabled
          />
          <FormItem name='numTo' form={form}>
            <Input
              style={{
                width: 100,
                textAlign: 'center',
              }}
              placeholder='Max'
            />
          </FormItem>
        </Input.Group>
      </FormItem>
    </>
  );
};

export default RangeNumberFormItem;
