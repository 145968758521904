import React from 'react';
import { Form, DatePicker } from 'antd';

import Layout from './layout';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

const RangeDateFormItem = (props) => {
  const { form } = props;

  function handleOnChange(dates) {
    form.setFieldsValue({ rangeDatePick: dates });
    if (props.setRadioValue) {
      props.setRadioValue(null);
    }
  }

  return (
    <FormItem
      label='选择日期'
      {...Layout.filterInputStyle}
      name='rangeDatePick'
      // onChange={console.log('form item change: ', form.getFieldValue())}
    >
      {<RangePicker allowClear onChange={(dates) => handleOnChange(dates)} />}
    </FormItem>
  );
};

export default RangeDateFormItem;
