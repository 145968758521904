import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import Feedback from '../../components/form/Feedback';
import { Card } from 'antd';
import FeedbackTable from '../../components/table/FeedbackTable';
import feedbackTableColumn from '../../../config/feedbackTableColumn';
import {
  fetchStudentList,
  fetchEnrollCourseList,
  fetchTeacherList,
  postFeedback,
  fetchEnrollCourseDetail,
  searchDetail,
} from './store/enrollmentAction';
import {
  fetchOneStudentFeedbackList,
  feedbackDataSource,
} from '../table/store/tableAction';

// 新生注册 or 学生再次enroll
const FeedbackPage = () => {
  const dispatch = useDispatch();
  const studentList = useSelector((state) => state.studentList);
  const teacherList = useSelector((state) => state.teacherList);
  const enrollCourseList = useSelector((state) => state.enrollCourseList);
  const enrollCourseDetail = useSelector((state) => state.enrollCourseDetail);
  const oneStudentFeedbackList = useSelector(
    (state) => state.oneStudentFeedbackList
  );

  const FeedbackCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchStudentList,
        fetchTeacherList,
        fetchEnrollCourseList,
        fetchOneStudentFeedbackList,
        feedbackDataSource,
        fetchEnrollCourseDetail,
        postFeedback,
        searchDetail,
      },
      dispatch
    );
  }, []);

  return (
    <>
      <Card>
        <Feedback
          {...FeedbackCbs}
          studentList={studentList}
          enrollCourseList={enrollCourseList}
          teacherList={teacherList}
          enrollCourseDetail={enrollCourseDetail}
        />
      </Card>
      <Card title='学生上课记录' style={{ margin: '10px 0' }}>
        <FeedbackTable
          oneStudentFeedbackList={oneStudentFeedbackList}
          feedbackTableColumn={feedbackTableColumn.columns}
        />
      </Card>
    </>
  );
};

export default FeedbackPage;
