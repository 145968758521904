import {
  IS_TABLE_LOADING,
  ONE_STUDENT_FEEDBACK_LIST,
  IS_DELETE_FEEDBACK,
  ENROLLMENT_LIST,
  ATTENDANCE_LIST,
  ENROLLMENT_LIST_TOTAL,
  ATTENDANCE_TOTAL,
  ENROLLMENT_LIST_STATISTIC,
  TRANSACTION_LIST_STATIC,
  ENROLLMENT_TABLE_QUERY,
  FEEDBACK_DETAIL_LIST,
  FEEDBACK_DETAIL_TOTAL,
  TRANSACTION_SUMMARY_LIST,
  TRANSACTION_SUMMARY_TOTAL,
} from './tableAction';

function isTableLoading(state = false, action) {
  const { type, payload } = action;
  switch (type) {
    case IS_TABLE_LOADING:
      return payload;
    default:
      return state;
  }
}

function oneStudentFeedbackList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ONE_STUDENT_FEEDBACK_LIST:
      return payload;
    default:
      return state;
  }
}

function isFeedbackDelete(state = false, action) {
  const { type, payload } = action;
  switch (type) {
    case IS_DELETE_FEEDBACK:
      return payload;
    default:
      return state;
  }
}

function enrollmentList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ENROLLMENT_LIST:
      return payload;
    default:
      return state;
  }
}

function attendanceList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ATTENDANCE_LIST:
      return payload;
    default:
      return state;
  }
}

function enrollmentListTotal(state = 0, action) {
  const { type, payload } = action;
  switch (type) {
    case ENROLLMENT_LIST_TOTAL:
      return payload;
    default:
      return state;
  }
}

function attendanceListTotal(state = 0, action) {
  const { type, payload } = action;
  switch (type) {
    case ATTENDANCE_TOTAL:
      return payload;
    default:
      return state;
  }
}

function enrollmentStatistic(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case ENROLLMENT_LIST_STATISTIC:
      return payload;
    default:
      return state;
  }
}

function transactionStatistic(state = -1, action) {
  const { type, payload } = action;
  switch (type) {
    case TRANSACTION_LIST_STATIC:
      return payload;
    default:
      return state;
  }
}

function feedbackDetailList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case FEEDBACK_DETAIL_LIST:
      return payload;
    default:
      return state;
  }
}

function feedbackDetailTotal(state = 0, action) {
  const { type, payload } = action;
  switch (type) {
    case FEEDBACK_DETAIL_TOTAL:
      return payload;
    default:
      return state;
  }
}

function transactionSummaryList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case TRANSACTION_SUMMARY_LIST:
      return payload;
    default:
      return state;
  }
}

function transactionSummaryTotal(state = 0, action) {
  const { type, payload } = action;
  switch (type) {
    case TRANSACTION_SUMMARY_TOTAL:
      return payload;
    default:
      return state;
  }
}

function enrollmentTableQuery(state = '', action) {
  const { type, payload } = action;
  switch (type) {
    case ENROLLMENT_TABLE_QUERY:
      return payload;
    default:
      return state;
  }
}

export default {
  isTableLoading,
  oneStudentFeedbackList,
  isFeedbackDelete,
  enrollmentList,
  attendanceList,
  enrollmentListTotal,
  attendanceListTotal,
  enrollmentStatistic,
  transactionStatistic,
  feedbackDetailList,
  feedbackDetailTotal,
  enrollmentTableQuery,
  transactionSummaryList,
  transactionSummaryTotal,
};
