import moment from 'moment';
import { dateFormat } from './util';

const today = [moment(moment(), dateFormat), moment(moment(), dateFormat)];
const thisMonth = [
  moment(moment().startOf('month'), dateFormat),
  moment(moment(), dateFormat),
];
const last90 = [
  moment(moment().subtract(90, 'days'), dateFormat),
  moment(moment(), dateFormat),
];

const coreData = true;

const all = [];

export default {
  today,
  thisMonth,
  last90,
  coreData,
  all,
};
