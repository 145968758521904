import React from 'react';
import { Card, Form } from 'antd';
import Layout from './layout';
import StudentFormItem from './StudentFormItem';
import SwitchFormItem from './SwitchFormItem';
import CourseFormItem from './CourseFormItem';
import TeacherFormItem from './TeacherFormItem';
import AdvisorFormItem from './AdvisorFormItem';
import InputFormItem from './InputFormItem';
import SubmitFormItem from './SubmitFormItem';
import DateFormItem from './DateFormItem';
import FormSteps from './FormSteps';
import dataTransform from '../../../util/dataTransform';
import Rules from '../../../util/Rules';

const EnrollCourse = (props) => {
  // console.log('enroll course component props is: ', props);
  const [form] = Form.useForm();
  const {
    history,
    fetchStudentList,
    studentList,
    fetchTeacherList,
    teacherList,
    fetchCoursesList,
    courseList,
    fetchAdvisorList,
    advisorList,
    setCourseEnrollModeVisible,
    isCourseEnrollModelVisible,
    redirectToHome,
    redirectToTransaction,
    postEnrollCourse,
  } = props;

  const handleSubmit = () => {
    const rawData = form.getFieldValue();
    console.log('rawData: ', rawData);
    const transactionData = dataTransform.enrollmentDateTransform(rawData);
    console.log('course info: ', transactionData);
    postEnrollCourse(transactionData);
  };

  return (
    <div>
      <FormSteps currentStep={1} />
      <Card title='请登记学生报课的信息' style={Layout.cardStyle}>
        <Form
          layout='horizontal'
          form={form}
          initialValues={{ reEnroll: false }}
        >
          <StudentFormItem
            rules={[]}
            form={form}
            fetchStudentList={fetchStudentList}
            studentList={studentList}
            itemStyle={Layout.filterInputStyle}
          />
          <SwitchFormItem />
          <CourseFormItem
            rules={[Rules.courseRequire]}
            courseList={courseList}
            fetchCoursesList={fetchCoursesList}
          />
          <TeacherFormItem
            rules={[Rules.trialTeacherRequire]}
            label='试听课老师'
            name='trialTeacher'
            form={form}
            teacherList={teacherList}
            fetchTeacherList={fetchTeacherList}
          />
          <TeacherFormItem
            rules={[Rules.teacherRequire]}
            label='任课老师'
            form={form}
            teacherList={teacherList}
            fetchTeacherList={fetchTeacherList}
          />
          <AdvisorFormItem
            rules={[Rules.advisorRequire]}
            form={form}
            advisorList={advisorList}
            fetchAdvisorList={fetchAdvisorList}
          />
          <InputFormItem
            label='课程价格'
            fieldName='total_price'
            placeholder='请输入出售课程价格'
            requireMessage='课程价格不能为空'
          />
          <DateFormItem />
          <InputFormItem
            label='备注'
            fieldName='comments'
            placeholder='备注 (i.e. 转课, 打折)'
          />
          <SubmitFormItem
            hasModel={true}
            handleSubmit={handleSubmit}
            isModelVisible={isCourseEnrollModelVisible}
            setModelVisible={setCourseEnrollModeVisible}
            ToHome={() => redirectToHome(history)}
            handleRedirect={() => redirectToTransaction(history)}
            instructions={[
              '成功为学生注册新课程',
              '新学生尚未登记转账',
              '是否为学生登记转账？',
            ]}
          />
        </Form>
      </Card>
    </div>
  );
};

export default EnrollCourse;
