import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popconfirm } from 'antd';
import { deleteFeedback } from '../../containers/table/store/tableAction';

const RenderDelete = (props) => {
  const dispatch = useDispatch();
  const isFeedbackDelete = useSelector((state) => state.isFeedbackDelete);

  function handleDelete(feedbackId) {
    console.log('delete id is ', feedbackId);
    if (feedbackId) dispatch(deleteFeedback(feedbackId));
  }

  const { record } = props;
  return (
    <Popconfirm
      title='Sure to delete?'
      onConfirm={() => handleDelete(record.key)}
    >
      <a>Delete</a>
    </Popconfirm>
  );
};

export default RenderDelete;
