import React, { useEffect } from 'react';
import { Card, Form } from 'antd';
import { useParams } from 'react-router-dom';

import DataTransform from '../../../util/dataTransform';
import InputFormItem from '../form/InputFormItem';
import EditFormItem from '../form/EditFormItem';
import InputTextArea from '../form/InputTextArea';
import DateFormItem from '../form/DateFormItem';

const Basic = (props) => {
  const [form] = Form.useForm();
  const {
    toggleBasicEdit,
    isBasicEdit,
    fetchStudentBasic,
    studentObj,
    editStudentBasic,
    isDataUpdate,
    setStudentBasicDataUpdate,
  } = props;

  const studentId = useParams().id;

  useEffect(() => {
    console.log('use effect on student basic: ', isDataUpdate);
    fetchStudentBasic(studentId);
    return () => setStudentBasicDataUpdate(false);
  }, [fetchStudentBasic, isDataUpdate, setStudentBasicDataUpdate, studentId]);

  useEffect(() => {
    form.setFieldsValue({
      student_school_id: studentObj.student_school_id,
      other_info: studentObj.other_info,
      cnname: studentObj.cnname,
      enname: studentObj.enname,
      phone: studentObj.phone,
      email: studentObj.email,
      wechat: studentObj.wechat,
      datepick: studentObj.studentDOB,
      createBy: studentObj.createBy,
      updateDate: studentObj.updatedAt,
      currentEnrollmentName: studentObj.currentEnrollmentName,
    });
  }, [form, studentObj]);

  const handleSubmit = () => {
    const rawData = form.getFieldValue();
    const submitData = DataTransform.studentPatchDataTransform(rawData);
    editStudentBasic(studentId, submitData);
  };

  return (
    <Card>
      <Form layout='horizontal' form={form}>
        <InputFormItem
          label='学生学号'
          fieldName='student_school_id'
          placeholder='请输学生学号'
          isDisabled={!isBasicEdit}
          form={form}
        />
        <InputFormItem
          label='中文名'
          fieldName='cnname'
          placeholder='请输入用户名'
          requireMessage='中文名不能为空'
          isDisabled={!isBasicEdit}
        />
        <InputFormItem
          label='英文名'
          fieldName='enname'
          placeholder='请输入英文名'
          requireMessage='英文名不能为空'
          isDisabled={!isBasicEdit}
        />
        <InputFormItem
          label='手机'
          fieldName='phone'
          placeholder='请输入手机号码'
          requireMessage='手机不能为空'
          isDisabled={!isBasicEdit}
        />
        <InputFormItem
          label='邮箱'
          fieldName='email'
          placeholder='请输入邮箱'
          requireMessage='邮箱不能为空'
          isDisabled={!isBasicEdit}
        />
        <InputFormItem
          label='微信'
          fieldName='wechat'
          placeholder='请输入微信id'
          isDisabled={!isBasicEdit}
        />
        <DateFormItem
          label={'生日日期'}
          isDisabled={!isBasicEdit}
          isRequire={false}
        />
        <InputTextArea
          label='备注'
          fieldName='other_info'
          placeholder='请输入学生更多信息'
          isDisabled={!isBasicEdit}
        />
        <InputFormItem label='修改人' fieldName='createBy' isDisabled={true} />
        <InputFormItem
          label='修改时间'
          fieldName='updateDate'
          isDisabled={true}
        />
        <EditFormItem
          toggleBasicEdit={toggleBasicEdit}
          isBasicEdit={isBasicEdit}
          handleSubmit={handleSubmit}
        />
      </Form>
    </Card>
  );
};

export default Basic;
