import React, { useEffect } from 'react';
import { Table, Skeleton } from 'antd';
import FeedbackDetailTableStatistic from './FeedbackDetailTableStatistic';

const FeedbackDetailTable = (props) => {
  const {
    isLoading,
    search,
    feedbackDetailList,
    feedbackDetailTotal,
    isFeedbackDelete,
    setIsFeedbackDelete,
    columns,
    pagination,
    setPagination,
    courseList,
    getQuery,
    form,
  } = props;

  useEffect(() => {
    let query = getQuery(form.getFieldValue(), courseList);
    search(pagination.current, pagination.pageSize, query);
  }, [pagination]);

  useEffect(() => {
    if (isFeedbackDelete) {
      setIsFeedbackDelete(false);
      window.location.reload();
    }
  }, [isFeedbackDelete]);

  const handleTableChange = ({ current, pageSize, total }) => {
    console.log(
      'pagination & total in feedback detail table: ',
      pagination,
      total
    );
    setPagination({ current, pageSize });
  };

  return isLoading ? (
    <Skeleton active />
  ) : (
    <Table
      dataSource={feedbackDetailList}
      columns={columns}
      pagination={{
        ...pagination,
        total: feedbackDetailTotal,
        position: ['topLeft'],
      }}
      onChange={handleTableChange}
      summary={(pageData) => {
        // console.log('page data is: ', pageData);
        return (
          <FeedbackDetailTableStatistic
            feedbackStatistic={feedbackDetailTotal}
          />
        );
      }}
    />
  );
};

export default FeedbackDetailTable;
