import React from 'react';
import { Form, Select, Input } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;

const NonActiveDaysFormItem = (props) => {
  const { form } = props;
  return (
    <FormItem label='不活跃大于' name='inActiveDay' form={form}>
      {
        <Input
          style={{ width: 100, textAlign: 'center' }}
          placeholder='不活跃天数'
        />
      }
    </FormItem>
  );
};

export default NonActiveDaysFormItem;
