import React from 'react';
import { Tag } from 'antd';
import CourseObj from '../objects/courseType';

const renderTag = (courseType) => {
  const target = CourseObj.courseType.filter(
    (item) => item.type === courseType
  );
  return target[0] ? <Tag color={target[0].color}>{target[0].name}</Tag> : null;
};

const columns = [
  {
    title: '名字',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '老师',
    dataIndex: 'teacherName',
    key: 'teacherName',
  },
  {
    title: '内容',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: '类型',
    dataIndex: 'courseType',
    key: 'courseType',
    render: (courseType) => renderTag(courseType),
  },
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date',
  },
];

export default { columns };
