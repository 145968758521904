import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Util from '../../../../util/util';
import { Row, Col, Breadcrumb } from 'antd';
import './index.less';
import { NavLink } from 'react-router-dom';

import User from './User';
import MenuObj from '../../../../objects/menuObj';
import LocalStorageService from '../../../../services/localStorageService';

function renderUser(user) {
  // console.log('check user: ', user);
  if (user) {
    return (
      <span>
        欢迎,&nbsp;
        <User user={user} logout={LocalStorageService.removeJWT} />
      </span>
    );
  }
}

const Header = () => {
  const menu = useSelector((state) => state.menu);
  const user = LocalStorageService.getCurrentUser();
  const sysTime = Util.formatDate(new Date().getTime());

  return (
    <div className='header'>
      <Row className='header-top'>
        <Col span={24}>{renderUser(user)}</Col>
      </Row>
      <Row className='breadcrumb'>
        <Col span={12} className='weather' style={{ textAlign: 'left' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
              {MenuObj.pathNameTransform(window.location.pathname)}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={12} className='weather'>
          <span className='date'>{sysTime}</span>
          <span className='city'>Melbourne</span>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
