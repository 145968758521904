import React, { useMemo, useState } from 'react';
import { Card, Form } from 'antd';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { dateFormat } from '../../../util/util';
import Query from '../../../util/query';
import FilterFormAttendance from '../../components/form/FilterFormAttendance';
import AttendanceTable from '../../components/table/AttendanceTable';
import AttendanceTableColumn from '../../../config/AttendanceTableColumn';
import { fetchAttendances as search } from './store/tableAction';
import {
  fetchStudentList,
  fetchTeacherList,
  fetchAdvisorList,
} from '../form/store/enrollmentAction';

const defaultInitialValue = [
  moment(moment(), dateFormat),
  moment(moment(), dateFormat),
];

const AttendanceTablePage = () => {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isTableLoading);
  const attendanceList = useSelector((state) => state.attendanceList);
  const studentList = useSelector((state) => state.studentList);
  const teacherList = useSelector((state) => state.teacherList);
  const advisorList = useSelector((state) => state.advisorList);
  const attendanceListTotal = useSelector((state) => state.attendanceListTotal);

  function getQuery() {
    console.log('get query', form.getFieldValue());
    let query = Query.constructQueryForAttendance(form.getFieldValue());
    console.log('query in filter form is: ', query);
    return query;
  }

  const AttendanceTableQueryCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchStudentList,
        fetchTeacherList,
        fetchAdvisorList,
        search,
      },
      dispatch
    );
  }, []);

  const AttendanceTableCbs = useMemo(() => {
    return bindActionCreators(
      {
        search,
      },
      dispatch
    );
  }, []);

  return (
    <div>
      <Card style={{ marginTop: 10 }}>
        <FilterFormAttendance
          {...AttendanceTableQueryCbs}
          form={form}
          getQuery={getQuery}
          studentList={studentList}
          teacherList={teacherList}
          advisorList={advisorList}
          pagination={pagination}
          defaultInitialValue={defaultInitialValue}
        />
      </Card>
      <Card style={{ marginTop: 10 }}>
        <AttendanceTable
          {...AttendanceTableCbs}
          isLoading={isLoading}
          attendanceList={attendanceList}
          attendanceListTotal={attendanceListTotal}
          columns={AttendanceTableColumn.columns}
          pagination={pagination}
          setPagination={setPagination}
          defaultInitialValue={defaultInitialValue}
          getQuery={getQuery}
        />
      </Card>
    </div>
  );
};

export default AttendanceTablePage;
