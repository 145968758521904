import React, { useRef } from 'react';

const AddTodo = (props) => {
  const { addTodo } = props;
  const inputRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const newText = inputRef.current.value;
    if (newText.length === 0) {
      return;
    }
    addTodo(newText);
    inputRef.current.value = '';
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input placeholder='Add todo' ref={inputRef} />
      </form>
    </div>
  );
};

export default AddTodo;
