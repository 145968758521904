import React, { useState } from 'react';
import { Form, Switch, Button } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;

const EditFormItem = (props) => {
  const { toggleBasicEdit, isBasicEdit, handleSubmit } = props;
  return (
    <FormItem {...Layout.formItemLayout} label='修改' name='isEdit'>
      {
        <>
          <Switch
            style={{ marginRight: '20px' }}
            checked={isBasicEdit}
            onChange={(check) => toggleBasicEdit(check)}
          />
          <FixConfirm isBasicEdit={isBasicEdit} handleSubmit={handleSubmit} />
        </>
      }
    </FormItem>
  );
};

const FixConfirm = (props) => {
  const { isBasicEdit, handleSubmit } = props;
  return isBasicEdit ? (
    <Button type='primary' onClick={handleSubmit}>
      确认修改
    </Button>
  ) : null;
};

export default EditFormItem;
