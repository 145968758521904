import axios from '../api/axios';
import { baseURL } from '../config/url';

const loginUrl = baseURL + '/users/public/login';

const loginReq = async (name, password) => {
  console.log('login url: ', loginUrl);
  console.log('param: ', name, password);
  return axios.post(`${loginUrl}`, {
    name: name,
    password: password,
  });
};

export default { loginReq };
