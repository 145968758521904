import { MENU, ROLE } from './pageAction';

function menu(state = '', action) {
  const { type, payload } = action;
  switch (type) {
    case MENU:
      return payload;
    default:
      return state;
  }
}
function role(state = '', action) {
  const { type, payload } = action;
  switch (type) {
    case ROLE:
      return payload;
    default:
      return state;
  }
}

export default { menu, role };
