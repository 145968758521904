import { message } from 'antd';

import EditService from '../../../../services/editService';
import formService from '../../../../services/formService';
import tableService from '../../../../services/tableService';
import DataTransform from '../../../../util/dataTransform';

export const STUDENT_BASIC_EDIT_MODE = 'STUDENT_BASIC_EDIT_MODE';
export const STUDENT_BASIC_EDIT_DATA = 'STUDENT_BASIC_EDIT_DATA';
export const STUDENT_BASIC_DATA_UPDATE = 'STUDENT_BASIC_DATA_UPDATE';
export const STUDENT_ENROLL_COURSE_LIST = 'STUDENT_ENROLL_COURSE_LIST';
export const STUDENT_TRANSACTION_LIST = 'STUDENT_TRANSACTION_LIST';

export function toggleBasicEdit(data) {
  return {
    type: STUDENT_BASIC_EDIT_MODE,
    payload: data,
  };
}

export function fetchStudentBasic(studentId) {
  return async (dispatch) => {
    try {
      let res = await EditService.getStudentBasic(studentId);
      if (res.data) {
        let displayData = DataTransform.studentBasicDataTransform(res.data);
        dispatch(studentData(displayData));
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
}

export function editStudentBasic(studentId, studentData) {
  return async (dispatch) => {
    try {
      let res = await EditService.editStudentBasic(studentId, studentData);
      if (res.data) {
        dispatch(toggleBasicEdit(false));
        dispatch(setStudentBasicDataUpdate(true));
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
}

export function setStudentBasicDataUpdate(data) {
  return {
    type: STUDENT_BASIC_DATA_UPDATE,
    payload: data,
  };
}

export function fetchEnrollCourseList(id) {
  console.log('enrollCourseList');
  return async (dispatch) => {
    try {
      let res = await formService.queryEnrollCourse(id);
      if (res.data) {
        const displayData = DataTransform.studentEnrollCourseDataTransform(
          res.data
        );
        dispatch(enrollCourseOption(displayData));
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
}

export function fetchStudentTransactionList(studentId) {
  return async (dispatch) => {
    try {
      let res = await tableService.getTransactionByStudent(studentId);
      if (res.data) {
        const displayData = DataTransform.studentTransactionDataTransform(
          res.data
        );
        dispatch(getStudentTransactions(displayData));
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
}

function enrollCourseOption(data) {
  return {
    type: STUDENT_ENROLL_COURSE_LIST,
    payload: data,
  };
}

function studentData(data) {
  return {
    type: STUDENT_BASIC_EDIT_DATA,
    payload: data,
  };
}

function getStudentTransactions(data) {
  return {
    type: STUDENT_TRANSACTION_LIST,
    payload: data,
  };
}
