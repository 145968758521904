const saleCourse = [
  { name: '流行初级', value: 1280 },
  { name: '基础强化', value: 6280 },
  { name: '流行综合', value: 11280 },
  { name: '半年五线谱', value: 8280 },
  { name: '流行经典', value: 5280 },
];

const feedbackCourse = [
  { name: '流行初级', value: 12 },
  { name: '基础强化', value: 128 },
  { name: '流行综合', value: 98 },
  { name: '半年五线谱', value: 12 },
  { name: '流行经典', value: 20 },
];

const salesTeacher = [
  { value: 80, name: '李老师' },
  { value: 90, name: '罗老师' },
];

const feedbackTeacher = [
  { value: 110, name: '李老师' },
  { value: 120, name: '罗老师' },
];

const courseName = '课程';

const teacherName = '老师';

const courseCategory = [
  '流行初级',
  '基础强化',
  '流行综合',
  '半年五线谱',
  '流行经典',
];

const teacherCategory = ['李老师', '罗老师'];

const attendanceBarData = [
  {
    name: '罗',
    type: 'bar',
    data: [3, 3, 10, 15, 8],
  },
  {
    name: '李',
    type: 'bar',
    data: [13, 7, 8, 13, 11],
  },
  {
    name: 'All',
    type: 'bar',
    data: [16, 10, 18, 28, 19],
  },
];
const teacherLegendData = ['罗', '李', 'All'];
const salesLegendData = ['All'];
const weekData = ['周三', '周四', '周五', '周六', '周日'];
const monthData = [
  '一月',
  '二月',
  '三月',
  '四月',
  '五月',
  '六月',
  '七月',
  '八月',
  '九月',
  '十月',
  '十一月',
  '十二月',
];

const salesBarData = [
  {
    name: 'All',
    type: 'bar',
    data: [
      9000,
      32000,
      58000,
      27000,
      19000,
      22000,
      33000,
      28000,
      39000,
      32000,
      21000,
      20000,
    ],
  },
];

export default {
  weekData,
  monthData,
  teacherLegendData,
  salesLegendData,
  saleCourse,
  feedbackCourse,
  salesTeacher,
  feedbackTeacher,
  courseName,
  teacherName,
  courseCategory,
  teacherCategory,
  attendanceBarData,
  salesBarData,
};
