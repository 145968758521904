import React, { useEffect } from 'react';
import { Card, Table } from 'antd';
import { useParams } from 'react-router-dom';

const Transactions = (props) => {
  const { fetchStudentTransactionList, transactions, columns } = props;
  const studentId = useParams().id;

  useEffect(() => {
    fetchStudentTransactionList(studentId);
  }, []);

  return (
    <Card>
      <Table dataSource={transactions} columns={columns} />
    </Card>
  );
};

export default Transactions;
