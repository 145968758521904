const courseType = [
  { type: 'LECTURE', name: '新课', color: 'geekblue' },
  { type: 'MIN45', name: '45分钟课', color: 'green'},
  { type: 'MIN60', name: '60分钟课', color: 'green'},
  { type: 'TUTORIAL', name: '指导课', color: 'green' },
  { type: 'TRAIL', name: '试听课', color: 'red' },
  { type: 'MISSING', name: '缺席', color: 'red' },
  { type: 'GROUP', name: 'group', color: 'blueviolet' },
  { type: 'OTHER', name: '其它', color: 'black' },
];

export default { courseType };
