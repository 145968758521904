import React from 'react';
import { Table, Skeleton } from 'antd';

const FeedbackTable = (props) => {
  const { isLoading, oneStudentFeedbackList, feedbackTableColumn } = props;
  return isLoading ? (
    <Skeleton active />
  ) : (
    <Table
      dataSource={oneStudentFeedbackList}
      columns={feedbackTableColumn}
      pagination={{
        position: ['topLeft'],
      }}
    />
  );
};

export default FeedbackTable;
