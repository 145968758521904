const courseRequire = {
  required: true,
  message: '课程不能为空',
};

const trialTeacherRequire = {
  required: true,
  message: '试听课老师不能为空',
};

const teacherRequire = {
  required: true,
  message: '任课老师不能为空',
};

const advisorRequire = {
  required: true,
  message: '课程顾问不能为空',
};

export default {
  courseRequire,
  trialTeacherRequire,
  teacherRequire,
  advisorRequire,
};
