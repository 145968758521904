import {
  STUDENT_BASIC_EDIT_MODE,
  STUDENT_BASIC_EDIT_DATA,
  STUDENT_BASIC_DATA_UPDATE,
  STUDENT_ENROLL_COURSE_LIST,
  STUDENT_TRANSACTION_LIST,
} from './studentAction';

const studentDefaultState = {
  student_school_id: '',
  cnname: '',
  enname: '',
  phone: '',
  email: '',
  wechat: '',
  createBy: '',
  updatedAt: '',
};

function isBasicEdit(state = false, action) {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_BASIC_EDIT_MODE:
      return payload;
    default:
      return state;
  }
}

function studentObj(state = studentDefaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_BASIC_EDIT_DATA:
      return payload;
    default:
      return state;
  }
}

function isStudentBasicDataUpdate(state = false, action) {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_BASIC_DATA_UPDATE:
      return payload;
    default:
      return state;
  }
}

function studentEnrollCourseList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_ENROLL_COURSE_LIST:
      return payload;
    default:
      return state;
  }
}

function studentTransactionList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_TRANSACTION_LIST:
      return payload;
    default:
      return state;
  }
}

export default {
  isBasicEdit,
  studentObj,
  isStudentBasicDataUpdate,
  studentEnrollCourseList,
  studentTransactionList,
};
