import React from 'react';
import { Form, Button, Modal, Popconfirm } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;

const SubmitFormItem = (props) => {
  const { hasModel } = props;
  // console.log('Submit Form Item has Model: ', hasModel);

  if (!hasModel) {
    const { handleClick } = props;
    return (
      <FormItem {...Layout.offsetLayout}>
        <Popconfirm
          title={'确认提交?'}
          placement='bottom'
          onConfirm={handleClick}
          okText='Yes'
          cancelText='No'
        >
          <Button type='primary'>提交</Button>
        </Popconfirm>
      </FormItem>
    );
  } else {
    const {
      handleSubmit,
      isModelVisible,
      setModelVisible,
      ToHome,
      handleRedirect,
      instructions,
    } = props;

    function handleClick() {
      handleSubmit();
    }

    function confirm() {
      console.log('confirmed');
    }

    return (
      <FormItem {...Layout.offsetLayout}>
        <Popconfirm
          title={'确认提交?'}
          placement='bottom'
          onConfirm={handleClick}
          okText='Yes'
          cancelText='No'
        >
          <Button type='primary'>提交</Button>
        </Popconfirm>
        <Modal
          title='提醒'
          visible={isModelVisible}
          onCancel={() => setModelVisible(false)}
          footer={[
            <Button key='no' onClick={ToHome}>
              取消
            </Button>,
            <Button key='ok' onClick={handleRedirect}>
              确定
            </Button>,
          ]}
        >
          {instructions.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        </Modal>
      </FormItem>
    );
  }
};

export default SubmitFormItem;
