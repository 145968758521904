import { dateFormat } from './util';
import moment from 'moment';

function rangeDateQuery(query, rangeDatePick) {
  if (rangeDatePick && rangeDatePick[0] && rangeDatePick[1]) {
    const from = rangeDatePick[0].format(dateFormat);
    const to = rangeDatePick[1].format(dateFormat);
    query = query + `&from=${from}&to=${to}`;
  }
  return query;
}

function constructCourseType(courseType, query) {
  for (let i = 0; i < courseType.length; i++) {
    query += `&courseType=${courseType[i]}`;
  }
  console.log('constructCourseType: ', query);
  return query;
}

const constructQueryForEnrollment = (income) => {
  console.log('constructQueryForEnrollment: ', income);
  let {
    student_id,
    teacher,
    course_advisor,
    rangeDatePick,
    numFrom,
    numTo,
    inActiveDay,
  } = income;
  let query = student_id ? `&student=${student_id}` : '';
  query = teacher ? query + `&teacher=${teacher}` : query;
  query = course_advisor ? query + `&course_advisor=${course_advisor}` : query;
  query = numFrom ? query + `&numFrom=${numFrom}` : query;
  query = numTo ? query + `&numTo=${numTo}` : query;
  query = inActiveDay ? query + `&inActiveDay=${inActiveDay}` : query;
  query = rangeDateQuery(query, rangeDatePick);
  return query;
};

const constructQueryForAttendance = (income) => {
  let { student_id, teacher, course_advisor, rangeDatePick } = income;
  let query = student_id ? `&student=${student_id}` : '';
  query = teacher ? query + `&currentTeacher=${teacher}` : query;
  query = course_advisor ? query + `&currentAdvisor=${course_advisor}` : query;
  query = rangeDateQuery(query, rangeDatePick);
  return query;
};

const constructQueryForFeedbackDetail = (income) => {
  let { student_id, teacher, course, courseType, rangeDatePick } = income;
  let query = student_id ? `&student=${student_id}` : '';
  query = teacher ? query + `&teacher=${teacher}` : query;
  query = course ? query + `&course=${course}` : query;
  query = courseType ? constructCourseType(courseType, query) : query;
  query = rangeDateQuery(query, rangeDatePick);
  console.log('transaction in query: ', query);
  return query;
};

const constructQueryForTransaction = (income) => {
  let { student_id, teacher, course_advisor, rangeDatePick } = income;
  let query = student_id ? `&student=${student_id}` : '';
  query = teacher ? query + `&trialTeacher=${teacher}` : query;
  query = course_advisor ? query + `&course_advisor=${course_advisor}` : query;
  query = rangeDateQuery(query, rangeDatePick);
  console.log('transaction in query: ', query);
  return query;
};

function getTransactionSummaryDefaultQuery() {
  let from = moment(moment().startOf('month'), dateFormat);
  let to = moment(moment(), dateFormat);
  const query = `&from=${from.format(dateFormat)}&to=${to.format(dateFormat)}`;
  return query;
}
const TransactionSummaryDefaultQuery = getTransactionSummaryDefaultQuery();

const mapCourseNameById = (courseId, courseList) => {
  const target = courseList.filter((item) => item._id === courseId);
  const courseName = target[0].name;
  return courseName;
};

const mapStudentIdByName = (studentName, studentList) => {};

export default {
  constructQueryForEnrollment,
  constructQueryForAttendance,
  constructQueryForFeedbackDetail,
  constructQueryForTransaction,
  mapCourseNameById,
  TransactionSummaryDefaultQuery,
  mapStudentIdByName,
};
