import loginService from '../../../services/loginService';
import localStorageService from '../../../services/localStorageService';
import { message } from 'antd';
import axios from '../../../api/axios';

export const MENU = 'MENU';
export const ROLE = 'ROLE';

export function requestLogin(payload) {
  return (dispatch) => {
    console.log('requestLoin payload: ', payload);
    const { name, password, history } = payload;
    const res = loginService.loginReq(name, password, history);
    res
      .then((res) => {
        console.log('requestLoin res: ', res);
        const result = res.data.token;
        if (result) {
          console.log('登陆成功 history is: ', history);
          localStorageService.setJWT(result);
          history.push('/home');
          axios.defaults.headers.common[
            'Authorization'
          ] = localStorageService.getAuth();
          dispatch(setRole(localStorageService.getCurrentRole()));
        } else {
          message.error('登陆失败');
        }
      })
      .catch((err) => {
        console.log('err: ', err.message);
        message.error('登陆失败');
      });
  };
}

export function setMenu(payload) {
  return {
    type: MENU,
    payload,
  };
}

export function setRole(payload) {
  return {
    type: ROLE,
    payload,
  };
}
