const red = '#ff4d4f';
const volcano = '#ff7a45';
const orange = '#ffa940';
const gold = '#ffc53d';
const yellow = '#ffec3d';
const lime = '#bae637';
const green = '#73d13d';
const cyan = '#36cfc9';
const blue = '#40a9ff';
const geekBlue = '#597ef7';
const purple = '#9254de';
const magenta = '#f759ab';
const grey = '#d9d9d9';

export default {
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekBlue,
  purple,
  magenta,
  grey,
};
