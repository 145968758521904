import React from 'react';
import { Row, Col } from 'antd';

import LoginComponent from '../../../components/loginComponet/loginComponent';

const LoginApp = (props) => {
  const { requestLogin, history, isJWTExist } = props;
  return (
    <div>
      <Row>
        <Col xl={8} lg={7} md={24}></Col>
        <Col xl={8} lg={10} md={24}>
          <LoginComponent requestLogin={requestLogin} history={history} />
        </Col>
        <Col xl={8} lg={7} md={24}></Col>
      </Row>
    </div>
  );
};

export default LoginApp;
