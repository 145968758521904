import React from 'react';
import { Form, Select } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;
const Option = Select.Option;

const rules = [
  {
    required: true,
    message: '货币不能为空',
  },
];

const CurrencyFormItem = (props) => {
  return (
    <FormItem
      label='货币'
      {...Layout.formItemLayout}
      name='currency'
      ruless={rules}
    >
      {
        <Select mode='single'>
          <Option value='AUD'>AUD</Option>
          <Option value='RMB'>RMB</Option>
        </Select>
      }
    </FormItem>
  );
};

export default CurrencyFormItem;
