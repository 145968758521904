import React, { useEffect } from 'react';
import { Table, Skeleton } from 'antd';
import AttendanceTableStatistic from './AttendanceTableStatistic';

const AttendanceTable = (props) => {
  const {
    isLoading,
    search,
    attendanceList,
    attendanceListTotal,
    columns,
    pagination,
    setPagination,
    getQuery,
  } = props;

  useEffect(() => {
    search(pagination.current, pagination.pageSize, getQuery());
  }, [pagination]);

  const handleTableChange = ({ current, pageSize, total }) => {
    console.log('pagination', pagination);
    setPagination({ current, pageSize });
  };

  return isLoading ? (
    <Skeleton active />
  ) : (
    <Table
      columns={columns}
      dataSource={attendanceList}
      pagination={{
        ...pagination,
        total: attendanceListTotal,
        position: ['topLeft'],
      }}
      onChange={handleTableChange}
      summary={(pageData) => {
        // console.log('page data is: ', pageData);
        return (
          <AttendanceTableStatistic attendanceStatistic={attendanceListTotal} />
        );
      }}
    />
  );
};

export default AttendanceTable;
