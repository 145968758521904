import React, { useMemo, useState } from 'react';
import { Card } from 'antd';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import TransactionSummaryColumn from '../../../config/TransactionSummaryColumn';
import TransactionSummaryTable from '../../components/table/TransactionSummaryTable';
import TransactionSummaryFilterForm from '../../components/form/TransactionSummaryFilterForm';
import { fetchTransactionSummary as search } from './store/tableAction';
import {
  fetchStudentList,
  fetchTeacherList,
  fetchAdvisorList,
} from '../form/store/enrollmentAction';

const TransactionTablePage = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isTableLoading);
  const transactionSummaryList = useSelector(
    (state) => state.transactionSummaryList
  );
  const transactionSummaryTotal = useSelector(
    (state) => state.transactionSummaryTotal
  );
  const studentList = useSelector((state) => state.studentList);
  const teacherList = useSelector((state) => state.teacherList);
  const advisorList = useSelector((state) => state.advisorList);
  const transactionStatistic = useSelector(
    (state) => state.transactionStatistic
  );

  const EnrollmentTableQueryCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchStudentList,
        fetchTeacherList,
        fetchAdvisorList,
        search,
      },
      dispatch
    );
  }, []);

  const TransactionSummaryTableCbs = useMemo(() => {
    return bindActionCreators(
      {
        search,
      },
      dispatch
    );
  }, []);

  return (
    <div>
      <Card style={{ marginTop: 10 }}>
        <TransactionSummaryFilterForm
          {...EnrollmentTableQueryCbs}
          studentList={studentList}
          teacherList={teacherList}
          advisorList={advisorList}
          pagination={pagination}
        />
      </Card>
      <Card style={{ marginTop: 10 }}>
        <TransactionSummaryTable
          {...TransactionSummaryTableCbs}
          isLoading={isLoading}
          transactionSummaryList={transactionSummaryList}
          transactionSummaryTotal={transactionSummaryTotal}
          columns={TransactionSummaryColumn.columns}
          pagination={pagination}
          setPagination={setPagination}
          transactionStatistic={transactionStatistic}
        />
      </Card>
    </div>
  );
};

export default TransactionTablePage;
