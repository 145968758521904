import React, { useEffect, useState } from 'react';
import TransactionSummaryStatistic from './TransactionSummaryStatistic';
import { Table, Skeleton } from 'antd';
import Query from '../../../util/query';

const TransactionSummaryTable = (props) => {
  const {
    isLoading,
    search,
    transactionSummaryList,
    transactionSummaryTotal,
    columns,
    pagination,
    setPagination,
    transactionStatistic,
  } = props;

  useEffect(() => {
    search(
      pagination.current,
      pagination.pageSize,
      Query.TransactionSummaryDefaultQuery
    );
  }, [pagination]);

  const handleTableChange = ({ current, pageSize, total }) => {
    console.log('pagination', pagination);
    setPagination({ current, pageSize });
  };

  return isLoading ? (
    <Skeleton active />
  ) : (
    <Table
      dataSource={transactionSummaryList}
      columns={columns}
      pagination={{
        ...pagination,
        total: transactionSummaryTotal,
        position: ['topLeft'],
      }}
      onChange={handleTableChange}
      summary={(pageData) => {
        // console.log('page data is: ', pageData);
        return (
          <TransactionSummaryStatistic
            transactionStatistic={transactionStatistic}
          />
        );
      }}
    />
  );
};

export default TransactionSummaryTable;
