import formService from '../../../../services/formService';
import { message } from 'antd';

export const STUDENT_LIST = 'STUDENT_LIST';
export const USER_LIST = 'USER_LIST';
export const TEACHER_LIST = 'TEACHER_LIST';
export const COURSE_LIST = 'COURSE_LIST';
export const ADVISOR_LIST = 'ADVISOR_LIST';
export const ENROLL_COURSE_LIST = 'ENROLL_COURSE_LIST';
export const ENROLL_COURSE_DETAIL = 'ENROLL_COURSE_DETAIL';
export const IS_ENROLL_STUDENT = 'IS_ENROLL_STUDENT';
export const IS_ENROLL_COURSE = 'IS_ENROLL_COURSE';
export const IS_ENROLL_TRANSACTION = 'IS_ENROLL_TRANSACTION';
export const IS_STUDENT_MODEL_VISIBLE = 'IS_STUDENT_MODEL_VISIBLE';
export const IS_COURSE_MODEL_VISIBLE = 'IS_COURSE_MODEL_VISIBLE';

export function redirectToHome(history) {
  return (dispatch) => {
    console.log('redirectToHome action');
    let path = '/home';
    history.push(path);
    dispatch(setStudentEnrollModelVisible(false));
  };
}

export function redirectToCourse(history) {
  return (dispatch) => {
    console.log('redirectToCourse action');
    let path = '/form/course';
    history.push(path);
    dispatch(setStudentEnrollModelVisible(false));
  };
}

export function redirectToDone(history) {
  console.log('enroll transaction done');
  let path = '/form/done';
  history.push(path);
}

export function redirectToTransaction(history) {
  return (dispatch) => {
    console.log('redirectToCourse action');
    let path = '/form/transaction';
    history.push(path);
    dispatch(setCourseEnrollModeVisible(false));
  };
}

export function fetchStudentList(input) {
  return async (dispatch) => {
    // console.log('input in action: ', input);
    let res = await formService.queryStudent(input);
    // console.log('StudentListOption in action: ', res.data);
    if (res.data) {
      dispatch(studentListOption(res.data.slice(0, 7)));
    }
  };
}

export function fetchUserList() {
  return async (dispatch) => {
    const cache = JSON.parse(localStorage.getItem('USER_LIST') || '{}');

    if (Date.now() < cache.expires) {
      dispatch(userListOption(cache.data));
      return;
    }

    let res = await formService.queryUser();
    if (res.data) {
      localStorage.setItem(
        'USER_LIST',
        JSON.stringify({
          expires: Date.now() + 60 * 1000,
          data: res.data,
        })
      );
      dispatch(userListOption(res.data));
    }
  };
}

export function fetchTeacherList() {
  // console.log('fetchTeacherList');
  return async (dispatch) => {
    const cache = JSON.parse(localStorage.getItem('TEACHER_LIST') || '{}');

    if (Date.now() < cache.expires) {
      dispatch(teacherListOption(cache.data));
      return;
    }

    let res = await formService.queryTeacher();
    if (res.data) {
      localStorage.setItem(
        'TEACHER_LIST',
        JSON.stringify({
          expires: Date.now() + 60 * 1000,
          data: res.data,
        })
      );
      dispatch(teacherListOption(res.data));
    }
  };
}

export function fetchCoursesList() {
  console.log('fetchCoursesList');
  return async (dispatch) => {
    const cache = JSON.parse(localStorage.getItem('COURSE_LIST') || '{}');

    if (Date.now() < cache.expires) {
      dispatch(courseListOption(cache.data));
      return;
    }

    let res = await formService.queryCourse();
    if (res.data) {
      localStorage.setItem(
        'COURSE_LIST',
        JSON.stringify({
          expires: Date.now() + 60 * 1000,
          data: res.data,
        })
      );
      dispatch(courseListOption(res.data));
    }
  };
}

export function fetchAdvisorList() {
  console.log('fetchAdvisorList');
  return async (dispatch) => {
    const cache = JSON.parse(localStorage.getItem('ADVISOR_LIST') || '{}');

    if (Date.now() < cache.expires) {
      dispatch(advisorListOption(cache.data));
      return;
    }

    let res = await formService.queryAdvisor();
    if (res.data) {
      localStorage.setItem(
        'ADVISOR_LIST',
        JSON.stringify({
          expires: Date.now() + 60 * 1000,
          data: res.data,
        })
      );
      dispatch(advisorListOption(res.data));
    }
  };
}

export function fetchEnrollCourseList(id) {
  console.log('enrollCourseList');
  return async (dispatch) => {
    let res = await formService.queryEnrollCourse(id);
    // console.log('fetchEnrollCourseList res is: ', res);
    try {
      if (res.data) {
        dispatch(enrollCourseOption(res.data));
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
}

export function fetchEnrollCourseDetail(enrollmentId) {
  console.log('fetchEnrollCourseDetail');
  return async (dispatch) => {
    dispatch(searchDetail({}));
    let res = await formService.searchDetail(enrollmentId);
    try {
      console.log('res in fetchEnrollCourseDetail is: ', res);
      dispatch(searchDetail(res.data.detail));
    } catch (error) {
      dispatch(searchDetail({}));
      message.error(error.response.data.message);
    }
  };
}

export function postEnrollStudent(userInfo) {
  // console.log('enroll student', userInfo);
  return async (dispatch) => {
    try {
      let res = await formService.enrollStudentRequest(userInfo);
      if (res) {
        console.log('enroll student result: ', res);
        dispatch(isEnrollStudent(true));
        message.success(`成功注册`);
        dispatch(setStudentEnrollModelVisible(true));
      }
    } catch (error) {
      console.error('error: ', error.response);
      message.error(error.response.data.message);
    }
  };
}

export function postFeedback(feedback) {
  console.log('post feedback');
  return async (dispatch) => {
    try {
      let res = await formService.makeFeedback(feedback);
      if (res) {
        // console.log('feedback result: ', res);
        message.success(`成功登记课程`);
      }
    } catch (error) {
      console.error('error: ', error.response);
      message.error(`递交有误`);
    }
  };
}

export function isEnrollStudent(data) {
  return {
    type: IS_ENROLL_STUDENT,
    payload: data,
  };
}

export function postEnrollCourse(data) {
  console.log('enroll course');
  return async (dispatch) => {
    try {
      let res = await formService.enrollCourseRequest(data);
      if (res) {
        // console.log('enroll course result: ', res);
        dispatch(isEnrollCourse(true));
        message.success(`成功登记课程`);
        dispatch(setCourseEnrollModeVisible(true));
      }
    } catch (error) {
      console.error('error: ', error.response);
      message.error(error.response.data.message);
    }
  };
}

export function postEnrollTransaction(data, history) {
  console.log('enroll transactions');
  return async (dispatch) => {
    try {
      let res = await formService.enrollTransactionRequest(data);
      if (res) {
        // console.log('enroll transaction result: ', res);
        dispatch(isEnrollTransaction(true));
        message.success(`成功登记转账`);
        redirectToDone(history);
      }
    } catch (error) {
      console.error('error: ', error.response);
      // message.error(error.response.data.message);
    }
  };
}

export function isEnrollTransaction(data) {
  return { type: IS_ENROLL_TRANSACTION, payload: data };
}

export function isEnrollCourse(data) {
  return {
    type: IS_ENROLL_COURSE,
    payload: data,
  };
}

export function setStudentEnrollModelVisible(input) {
  return {
    type: IS_STUDENT_MODEL_VISIBLE,
    payload: input,
  };
}

export function setCourseEnrollModeVisible(input) {
  return {
    type: IS_COURSE_MODEL_VISIBLE,
    payload: input,
  };
}

function studentListOption(data) {
  return {
    type: STUDENT_LIST,
    payload: data,
  };
}

function userListOption(data) {
  return {
    type: USER_LIST,
    payload: data,
  };
}

function teacherListOption(data) {
  return {
    type: TEACHER_LIST,
    payload: data,
  };
}

function advisorListOption(data) {
  return {
    type: ADVISOR_LIST,
    payload: data,
  };
}

function courseListOption(data) {
  return {
    type: COURSE_LIST,
    payload: data,
  };
}

function enrollCourseOption(data) {
  return {
    type: ENROLL_COURSE_LIST,
    payload: data,
  };
}

export function searchDetail(data) {
  return {
    type: ENROLL_COURSE_DETAIL,
    payload: data,
  };
}
