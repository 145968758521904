import React from 'react';
import { Card, Col, Row } from 'antd';
import CardsWrapper from '../../components/charts/cards/CardsWrapper';
import SalesCategoryPie from '../../components/charts/SalesCategoryPie';
import category from '../../../Mock/pies/category';

const cardStyle = { margin: '12px' };

const TeacherChartsPage = () => {
  return (
    <>
      <Row>
        <CardsWrapper
          salesNum='$36890'
          subSalesNum='10'
          trialNum='95%'
          subTrialNum='10'
          attendanceNum='88'
          subAttendanceNum='10'
          balanceNum='$5200'
          subBalanceNum='-10'
        />
      </Row>
      <Row>
        <Col xl={12} xs={24}>
          <Card
            bordered={true}
            style={cardStyle}
            hoverable
            title='老师销售占比'
          >
            <SalesCategoryPie
              data={{
                name: category.teacherName,
                category: category.teacherCategory,
                data: category.salesTeacher,
              }}
            />
          </Card>
        </Col>
        <Col xl={12} xs={24}>
          <Card
            bordered={true}
            style={cardStyle}
            hoverable
            title='老师上课数量占比'
          >
            <SalesCategoryPie
              data={{
                name: category.teacherName,
                category: category.teacherCategory,
                data: category.feedbackTeacher,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TeacherChartsPage;
