import todos from '../view/containers/todos/store/todoReducer';
import enrollmentReducer from '../view/containers/form/store/enrollmentReducer';
import tableReducer from '../view/containers/table/store/tableReducer';
import studentReducer from '../view/containers/student/store/studentReducer';
import pageReducer from '../view/containers/containerStore/pageReducer';

export default {
  ...todos,
  ...enrollmentReducer,
  ...tableReducer,
  ...studentReducer,
  ...pageReducer,
};
