import React, { useState } from 'react';
import { Form, Button, Row, Radio } from 'antd';

import Layout from './layout';
import Global from '../../../util/global';
import StudentFormItem from './StudentFormItem';
import TeacherFormItem from './TeacherFormItem';
import CourseFormItem from './CourseFormItem';
import RangeDateFormItem from './RangeDateFormItem';
import CourseTypeFormItem from './CourseTypeFormItem';
import BrForForm from './BrForForm';

const FormItem = Form.Item;

const FeedbackDetailFilterForm = (props) => {
  const {
    fetchStudentList,
    studentList,
    teacherList,
    fetchTeacherList,
    courseList,
    fetchCoursesList,
    search,
    pagination,
    defaultInitialValue,
    getQuery,
    form,
  } = props;

  const [radioValue, setRadioValue] = useState(Global.thisMonth);

  function query() {
    let query = getQuery(form.getFieldValue(), courseList);
    console.log('query is: ', query);
    search(pagination.current, pagination.pageSize, query);
  }

  function handleSizeChange(e) {
    console.log('handleSizeChange: ', e);
    form.setFieldsValue({ rangeDatePick: e.target.value });
    setRadioValue(e.target.value);
    let query = getQuery(form.getFieldValue(), courseList);
    search(pagination.current, pagination.pageSize, query);
  }

  function reset() {
    form.resetFields();
    form.setFieldsValue({ rangeDatePick: [] });
    setRadioValue(null);
  }

  return (
    <Form form={form} initialValues={{ rangeDatePick: Global.thisMonth }}>
      <Row gutter={24}>
        <StudentFormItem
          formItemLayout={{}}
          rules={[]}
          form={form}
          fetchStudentList={fetchStudentList}
          studentList={studentList}
          // itemStyle={Layout.filterInputStyle}
          autoCompleteStyle={Layout.autoCompleteStyle}
        />
        <TeacherFormItem
          formItemLayout={{}}
          rules={[]}
          form={form}
          teacherList={teacherList}
          fetchTeacherList={fetchTeacherList}
          itemStyle={Layout.filterInputStyle}
        />
        <CourseFormItem
          formItemLayout={{}}
          rules={[]}
          courseList={courseList}
          fetchCoursesList={fetchCoursesList}
          itemStyle={Layout.filterInputStyle}
        />
        <CourseTypeFormItem
          rules={[]}
          itemStyle={Layout.corseTypeStyle}
          formItemLayout={{}}
        />
        <BrForForm />
        <RangeDateFormItem
          form={form}
          initValue={[]}
          setRadioValue={setRadioValue}
        />
        <BrForForm />
        <Radio.Group value={radioValue} onChange={(e) => handleSizeChange(e)}>
          <Radio.Button value={Global.today}>今日</Radio.Button>
          <Radio.Button value={Global.thisMonth}>本月</Radio.Button>
          <Radio.Button value={Global.last90}>季度</Radio.Button>
          <Radio.Button value={Global.all}>全部</Radio.Button>
        </Radio.Group>
        <Button type='primary' style={{ margin: '0 20px' }} onClick={query}>
          查询
        </Button>
        <FormItem>
          <Button onClick={reset}>重置</Button>
        </FormItem>
      </Row>
      <Row gutter={24}></Row>
    </Form>
  );
};

export default FeedbackDetailFilterForm;
