import React from 'react';
import moment from 'moment';
import { Popconfirm, Modal } from 'antd';

const columns = [
  {
    title: '课程名字',
    dataIndex: 'courseName',
    key: 'enroll_courseName',
    editable: true,
  },
  {
    title: '老师名字',
    dataIndex: 'teacherName',
    key: 'enroll_teacherName',
    editable: true,
  },
  {
    title: '顾问名字',
    dataIndex: 'advisorName',
    key: 'enroll_advisorName',
    editable: true,
  },
  {
    title: '报课日期',
    dataIndex: 'enrollDate',
    key: 'enroll_enrollDate',
    editable: true,
    sorter: (a, b) => moment(a.enrollDate).diff(moment(b.enrollDate)),
  },
  {
    title: '报课价格',
    dataIndex: 'total_price',
    key: 'enroll_total_price',
    editable: true,
    sorter: (a, b) => a.total_price - b.total_price,
  },
  {
    title: '未付金额',
    dataIndex: 'balance',
    key: 'enroll_balance',
    editable: true,
    sorter: (a, b) => a.balance - b.balance,
  },
];

export default { columns };
