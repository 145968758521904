import React from 'react';
import { Table, Tag, Typography } from 'antd';
import '../../../style/table.less';
const { Text } = Typography;
const { Summary } = Table;

const purple = '#9254de';
const blue = '#40a9ff';
const red = '#ff4d4f';

const EnrollmentTableStatistic = (props) => {
  const { totalEnrollPrice, totalBalance } = props;
  return (
    <>
      <Summary.Row>
        <Summary.Cell>报课总额</Summary.Cell>
        <Summary.Cell colSpan={1}>
          <Tag color={purple}>{totalEnrollPrice}</Tag>
        </Summary.Cell>
      </Summary.Row>
      <Summary.Row>
        <Summary.Cell>收款总额</Summary.Cell>
        <Summary.Cell colSpan={1}>
          <Tag color={blue}>{totalEnrollPrice - totalBalance}</Tag>
        </Summary.Cell>
      </Summary.Row>
      <Summary.Row>
        <Summary.Cell>未收总额</Summary.Cell>
        <Summary.Cell colSpan={1}>
          <Tag color={red}>{totalBalance}</Tag>
        </Summary.Cell>
      </Summary.Row>
    </>
  );
};

export default EnrollmentTableStatistic;
