import React from 'react';
import { Form, Select } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;
const Option = Select.Option;

const defaultRules = [
  {
    required: true,
    message: '上课类型不能为空',
  },
];

const CourseTypeSingleFormItem = (props) => {
  let layouts = props.formItemLayout
    ? props.formItemLayout
    : Layout.formItemLayout;
  let rules = props.rules ? props.rules : defaultRules;
  return (
    <FormItem label='上课类型' {...layouts} name='courseType' rules={rules}>
      {
        <Select mode='single' style={props.itemStyle} allowClear>
          <Option value='LECTURE'>新课</Option>
          <Option value='MIN45'>45分钟课</Option>
          <Option value='MIN60'>60分钟课</Option>
          <Option value='TUTORIAL'>指导课</Option>
          <Option value='TRAIL'>试听课</Option>
          <Option value='MISSING'>缺席</Option>
          <Option value='GROUP'>group</Option>
          <Option value='OTHER'>其他(需备注)</Option>
        </Select>
      }
    </FormItem>
  );
};

export default CourseTypeSingleFormItem;
