import React from 'react';

import {
  HomeOutlined,
  FormOutlined,
  TableOutlined,
  BarChartOutlined,
} from '@ant-design/icons';

const menuList = [
  {
    icon: <HomeOutlined />,
    title: 'Home',
    key: '/home',
  },
  {
    icon: <FormOutlined />,
    title: '登记',
    key: '/form',
    children: [
      {
        icon: '',
        title: '学生登记',
        key: '/form/student',
      },
      {
        icon: '',
        title: '报课登记',
        key: '/form/course',
      },
      {
        icon: '',
        title: '付款登记',
        key: '/form/transaction',
      },
      {
        icon: '',
        title: '上课登记',
        key: '/form/feedback',
      },
      {
        icon: '',
        title: '代办事项',
        key: '/form/todolist',
      },
    ],
  },
  {
    icon: <TableOutlined />,
    title: '表格',
    key: '/table',
    children: [
      {
        icon: '',
        title: '报课表格',
        key: '/table/enrollment',
      },
      {
        icon: '',
        title: '上课表格',
        key: '/table/feedback',
      },
      {
        icon: '',
        title: '签到表格',
        key: '/table/attendance',
      },
    ],
  },
];
export default menuList;
