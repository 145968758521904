import { ADD, REMOVE, TOGGLE } from './action';

function todos(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ADD:
      return [...state, payload];
    case REMOVE:
      return state.filter((item) => item.id !== payload);
    case TOGGLE:
      return state.map((todo) => {
        return todo.id === payload
          ? {
              ...todo,
              complete: !todo.complete,
            }
          : todo;
      });
    default:
      return state;
  }
}

export default { todos };
