const getOptionPie = (input) => {
  console.log('input is: ', input);
  let option = {
    legend: {
      orient: 'vertical',
      right: 10,
      top: 20,
      bottom: 20,
      data: input.category,
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',
    },
    series: [
      {
        name: input.name,
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'right',
        },
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: input.data,
      },
    ],
  };
  return option;
};

export default { getOptionPie };
