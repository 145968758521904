import React from 'react';
import { Table, Tag } from 'antd';
import '../../../style/table.less';
const { Summary } = Table;

const tagColor = '#9254de';

const TransactionSummaryStatistic = (props) => {
  const { transactionStatistic } = props;
  return (
    <>
      <Summary.Row>
        <Summary.Cell>报课总额</Summary.Cell>
        <Summary.Cell colSpan={1}>
          <Tag color={tagColor}>{transactionStatistic}</Tag>
        </Summary.Cell>
      </Summary.Row>
    </>
  );
};

export default TransactionSummaryStatistic;
