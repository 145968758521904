import React from 'react';
import InputFormItem from './InputFormItem';

/**
 * 展示user
 */
const ShowUserDetail = (props) => {
  const { displayDetail, displayData } = props;
  let label = props.label ? props.label : '相关人员';
  let prefix = props.prefix ? props.prefix : '';
  const isDisplay =
    Object.keys(displayDetail).length !== 0 &&
    (displayDetail[displayData] || displayDetail[displayData] === 0);

  return !isDisplay ? null : (
    <InputFormItem
      label={label}
      fieldName='balance'
      placeholder={`${prefix}${displayDetail[displayData]}`}
      isDisabled={true}
    />
  );
};

export default ShowUserDetail;
