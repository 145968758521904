import React from 'react';
import { Result, Button } from 'antd';

const FormDone = () => {
  return (
    <div>
      <Result status='success' title='Successfully Record Transaction!' />
    </div>
  );
};

export default FormDone;
