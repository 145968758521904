import React from 'react';
import {Form, Switch} from 'antd';
import Layout from './layout';

const defaultRules = [
  {
    required: true,
    message: 'id不能为空',
  },
];

const SwitchFormItem = (props) => {
  const {form} = props;
  let layouts = props.formItemLayout
    ? props.formItemLayout
    : Layout.formItemLayout;
  let rules = props.rules ? props.rules : defaultRules;
  let itemLabel = props.itemLabel ? props.itemLabel : '转课学生';
  itemLabel = itemLabel === 'NONE' ? null : itemLabel;

  return (
    <Form.Item
      label={itemLabel}
      {...layouts}
      name='reEnroll'
      rules={rules}
      form={form}
      // onChange={console.log('form item change: ', form.getFieldValue())}
    >
      {<Switch checkedChildren='是' unCheckedChildren='否' />}
    </Form.Item>
  );
};

export default SwitchFormItem;
