import {
  STUDENT_LIST,
  USER_LIST,
  TEACHER_LIST,
  COURSE_LIST,
  ADVISOR_LIST,
  IS_ENROLL_STUDENT,
  IS_STUDENT_MODEL_VISIBLE,
  IS_COURSE_MODEL_VISIBLE,
  ENROLL_COURSE_LIST,
  ENROLL_COURSE_DETAIL,
} from './enrollmentAction';

function studentList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_LIST:
      return payload;
    default:
      return state;
  }
}

function userList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LIST:
      return payload;
    default:
      return state;
  }
}

function teacherList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case TEACHER_LIST:
      return payload;
    default:
      return state;
  }
}

function advisorList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ADVISOR_LIST:
      return payload;
    default:
      return state;
  }
}

function courseList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case COURSE_LIST:
      return payload;
    default:
      return state;
  }
}

function enrollCourseList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ENROLL_COURSE_LIST:
      return payload;
    default:
      return state;
  }
}

function isEnrollStudent(state = false, action) {
  const { type, payload } = action;
  switch (type) {
    case IS_ENROLL_STUDENT:
      return payload;
    default:
      return state;
  }
}

function isStudentModelVisible(state = false, action) {
  const { type, payload } = action;
  switch (type) {
    case IS_STUDENT_MODEL_VISIBLE:
      return payload;
    default:
      return state;
  }
}

function isCourseEnrollModelVisible(state = false, action) {
  const { type, payload } = action;
  switch (type) {
    case IS_COURSE_MODEL_VISIBLE:
      return payload;
    default:
      return state;
  }
}

function enrollCourseDetail(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case ENROLL_COURSE_DETAIL:
      return payload;
    default:
      return state;
  }
}

export default {
  studentList,
  userList,
  teacherList,
  advisorList,
  courseList,
  enrollCourseList,
  isEnrollStudent,
  isStudentModelVisible,
  isCourseEnrollModelVisible,
  enrollCourseDetail,
};
