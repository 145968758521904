import React from 'react';

function App(props) {
  return (
    <div className='App'>
      <div>{props.children}</div>
    </div>
  );
}

export default App;
