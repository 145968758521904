import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';

const columns = [
  {
    title: '出勤日期',
    dataIndex: 'attendTime',
    key: 'attendTime',
    sorter: (a, b) => moment(a.attendDate).diff(moment(b.attendDate)),
  },
  {
    title: '余款',
    dataIndex: 'balance',
    key: 'balance',
    render: (text) => balanceRender(text),
  },
  {
    title: '课程名字',
    dataIndex: 'courseName',
    key: 'courseName',
  },
  {
    title: '老师名字',
    dataIndex: 'teacherName',
    key: 'teacherName',
    responsive: ['lg'],
  },
  {
    title: '顾问名字',
    dataIndex: 'advisorName',
    key: 'advisorName',
    responsive: ['lg'],
  },
  {
    title: '报课日期',
    dataIndex: 'enrollDate',
    key: 'enrollDate',
    responsive: ['lg'],
    sorter: (a, b) => moment(a.enrollDate).diff(moment(b.enrollDate)),
  },
  {
    title: '报名到今天',
    dataIndex: 'toNow',
    key: 'toNow',
    responsive: ['lg'],
    render: (text) => toNowRender(text),
  },
];

const balanceRender = (text) => {
  let color = text == 0 ? 'green' : 'red';
  let context = '$' + text;
  return <Tag color={color}>{context}</Tag>;
};

const toNowRender = (text) => {
  let color = 'green';
  let context = '';
  if (text < 30) {
    context = text + '天 : 一个月内';
    color = 'green';
  } else if (text < 60) {
    context = text + '天 : 两个月内';
    color = 'blue';
  } else if (text < 120) {
    context = text + '天 : 四个月内';
    color = 'orange';
  } else if (text < 180) {
    context = text + '天 : 半年内';
    color = '#ffa39e';
  } else if (text < 360) {
    context = text + '天 : 一年内';
    color = '#ff4d4f';
  } else {
    context = text + '天 : 超过一年';
    color = '#cf1322';
  }

  return <Tag color={color}>{context}</Tag>;
};

export default { columns };
