import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Table, Skeleton } from 'antd';

const Attendance = (props) => {
  const studentId = useParams().id;
  const {
    isLoading,
    attendanceList,
    fetchAttendances,
    pagination,
    columns,
  } = props;

  const query = `&student=${studentId}`;

  useEffect(() => {
    fetchAttendances(pagination.current, pagination.pageSize, query);
    return () => {
      console.log('离开页面');
    };
  }, []);
  return isLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Card title='学生签到记录' style={{ margin: '10px 0' }}>
        <Table
          dataSource={attendanceList}
          columns={columns}
          pagination={{
            ...pagination,
            position: ['topLeft'],
          }}
        />
      </Card>
    </>
  );
};

export default Attendance;
