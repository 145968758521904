import React, { useMemo, useState } from 'react';
import { Card, Row, Col, Form } from 'antd';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { dateFormat } from '../../../util/util';
import feedbackDetailTableColumn from '../../../config/feedbackDetailTableColumn';
import FeedbackDetailTable from '../../components/table/FeedbackDetailTable';
import FeedbackDetailFilterForm from '../../components/form/FeedbackDetailFilterForm';
import {
  fetchFeedbackDetails as search,
  isFeedbackDelete as setIsFeedbackDelete,
  getQueryForFeedbackDetail,
} from './store/tableAction';
import {
  fetchStudentList,
  fetchTeacherList,
  fetchCoursesList,
} from '../form/store/enrollmentAction';

const defaultInitialValue = [
  moment(moment().startOf('month'), dateFormat),
  moment(moment(), dateFormat),
];

const FeedbackTablePage = () => {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isTableLoading);
  const feedbackDetailList = useSelector((state) => state.feedbackDetailList);
  const feedbackDetailTotal = useSelector((state) => state.feedbackDetailTotal);
  const isFeedbackDelete = useSelector((state) => state.isFeedbackDelete);
  const studentList = useSelector((state) => state.studentList);
  const teacherList = useSelector((state) => state.teacherList);
  const courseList = useSelector((state) => state.courseList);

  const FeedbackDetailTableQueryCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchStudentList,
        fetchTeacherList,
        fetchCoursesList,
        search,
      },
      dispatch
    );
  }, []);

  const FeedbackDetailTableCbs = useMemo(() => {
    return bindActionCreators(
      {
        search,
        setIsFeedbackDelete,
      },
      dispatch
    );
  }, []);

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Card style={{ marginTop: 10 }}>
            <FeedbackDetailFilterForm
              {...FeedbackDetailTableQueryCbs}
              form={form}
              getQuery={getQueryForFeedbackDetail}
              studentList={studentList}
              teacherList={teacherList}
              courseList={courseList}
              pagination={pagination}
              defaultInitialValue={defaultInitialValue}
            />
          </Card>
          <Card style={{ marginTop: 10 }}>
            <FeedbackDetailTable
              {...FeedbackDetailTableCbs}
              isLoading={isLoading}
              form={form}
              getQuery={getQueryForFeedbackDetail}
              feedbackDetailList={feedbackDetailList}
              feedbackDetailTotal={feedbackDetailTotal}
              isFeedbackDelete={isFeedbackDelete}
              columns={feedbackDetailTableColumn.columns}
              pagination={pagination}
              setPagination={setPagination}
              courseList={courseList}
              defaultInitialValue={defaultInitialValue}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FeedbackTablePage;
