import jwtDecode from 'jwt-decode';

const JWT = '_JWT_TOKEN';

function checkJWT() {
  // console.log('called checkJWT');
  const token = JSON.parse(localStorage.getItem(JWT));
  if (token) {
    // console.log('jwt decode: ', jwtDecode(token));
    const expire = jwtDecode(token).exp;
    if (Date.now() < expire * 1000) {
      // console.log('token is valid');
      return true;
    }
    // console.log('token is NOT valid: ', expire);
  }
  return false;
}

function setJWT(jwt) {
  const jwtJson = JSON.stringify(jwt);
  localStorage.setItem(JWT, jwtJson);
}

function removeJWT() {
  localStorage.removeItem(JWT);
}

function getAuth() {
  const token = JSON.parse(localStorage.getItem(JWT));
  let header = 'Bearer ' + token;
  return header;
}

function getCurrentUser() {
  const token = JSON.parse(localStorage.getItem(JWT));
  if (token) {
    return jwtDecode(token).name;
  }
}

function getCurrentRole() {
  const token = JSON.parse(localStorage.getItem(JWT));
  if (token) {
    return jwtDecode(token).role;
  }
}

const isAdmin = getCurrentRole() === 0 || getCurrentRole() === 1;

export default {
  checkJWT,
  setJWT,
  getAuth,
  getCurrentUser,
  getCurrentRole,
  isAdmin,
  removeJWT,
};
