import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { Tag } from 'antd';
import { dateFormat } from '../util/util';
import Colors from '../util/colors';

const columns_admin = [
  {
    title: '学校id',
    dataIndex: 'student_school_id',
    key: 'student_school_id',
    sorter: (a, b) => a.student_school_id - b.student_school_id,
  },
  {
    title: '学生名字',
    dataIndex: 'studentName',
    key: 'studentName',
    render: (text, record, index) => {
      return <NavLink to={`/student/${record.student}`}>{text}</NavLink>;
    },
  },
  {
    title: '课程名字',
    dataIndex: 'courseName',
    key: 'courseName',
  },
  {
    title: '上课',
    dataIndex: 'feedbackCounter',
    key: 'feedbackCounter',
  },
  {
    title: '出勤',
    dataIndex: 'attendanceCounter',
    key: 'attendanceCounter',
  },
  {
    title: '不活跃长度',
    dataIndex: 'lastActiveDate',
    key: 'lastActiveDate',
    render: (text, row) => noneActiveDaysRender(text, row),
  },
  {
    title: '老师名字',
    dataIndex: 'teacherName',
    key: 'teacherName',
    responsive: ['lg'],
  },
  {
    title: '顾问名字',
    dataIndex: 'advisorName',
    key: 'advisorName',
    responsive: ['lg'],
  },
  {
    title: '报课日期',
    dataIndex: 'enrollDate',
    key: 'enrollDate',
    responsive: ['lg'],
    sorter: (a, b) => moment(a.enrollDate).diff(moment(b.enrollDate)),
  },
  {
    title: '报课价格',
    dataIndex: 'total_price',
    key: 'total_price',
    responsive: ['lg'],
    sorter: (a, b) => a.total_price - b.total_price,
  },
  {
    title: '已付金额',
    dataIndex: 'paid',
    key: 'paid',
    sorter: (a, b) => a.balance - b.balance,
  },
  {
    title: '未付金额',
    dataIndex: 'balance',
    key: 'balance',
    sorter: (a, b) => a.balance - b.balance,
  },
  {
    title: '备注',
    dataIndex: 'comments',
    key: 'comments',
    responsive: ['lg'],
    ellipsis: true,
  },
];

const columns_admin_core = [
  {
    title: '学校id',
    dataIndex: 'student_school_id',
    key: 'student_school_id',
    sorter: (a, b) => a.student_school_id - b.student_school_id,
  },
  {
    title: '学生名字',
    dataIndex: 'studentName',
    key: 'studentName',
    render: (text, record, index) => {
      return <NavLink to={`/student/${record.student}`}>{text}</NavLink>;
    },
  },
  {
    title: '课程名字',
    dataIndex: 'courseName',
    key: 'courseName',
  },
  {
    title: '上课',
    dataIndex: 'feedbackCounter',
    key: 'feedbackCounter',
  },
  {
    title: '出勤',
    dataIndex: 'attendanceCounter',
    key: 'attendanceCounter',
  },
  {
    title: '不活跃长度',
    dataIndex: 'lastActiveDate',
    key: 'lastActiveDate',
    render: (text, row) => noneActiveDaysRender(text, row),
  },
  {
    title: '老师名字',
    dataIndex: 'teacherName',
    key: 'teacherName',
    responsive: ['lg'],
  },
  {
    title: '报课日期',
    dataIndex: 'enrollDate',
    key: 'enrollDate',
    responsive: ['lg'],
    sorter: (a, b) => moment(a.enrollDate).diff(moment(b.enrollDate)),
  },
  {
    title: '未付金额',
    dataIndex: 'balance',
    key: 'balance',
    sorter: (a, b) => a.balance - b.balance,
  },
];

const columns_user = [
  {
    title: '学校id',
    dataIndex: 'student_school_id',
    key: 'student_school_id',
    sorter: (a, b) => a.student_school_id - b.student_school_id,
  },
  {
    title: '学生名字',
    dataIndex: 'studentName',
    key: 'studentName',
    render: (text, record, index) => {
      return <NavLink to={`/student/${record.student}`}>{text}</NavLink>;
    },
  },
  {
    title: '上课',
    dataIndex: 'feedbackCounter',
    key: 'feedbackCounter',
  },
  {
    title: '出勤',
    dataIndex: 'attendanceCounter',
    key: 'attendanceCounter',
  },
  {
    title: '不活跃长度',
    dataIndex: 'lastActiveDate',
    key: 'lastActiveDate',
    render: (text, row) => noneActiveDaysRender(text, row),
  },
  {
    title: '课程名字',
    dataIndex: 'courseName',
    key: 'courseName',
  },
  {
    title: '老师名字',
    dataIndex: 'teacherName',
    key: 'teacherName',
    responsive: ['lg'],
  },
  {
    title: '顾问名字',
    dataIndex: 'advisorName',
    key: 'advisorName',
    responsive: ['lg'],
  },
  {
    title: '报课日期',
    dataIndex: 'enrollDate',
    key: 'enrollDate',
    responsive: ['lg'],
    sorter: (a, b) => moment(a.enrollDate).diff(moment(b.enrollDate)),
  },
  {
    title: '未付金额',
    dataIndex: 'balance',
    key: 'balance',
    sorter: (a, b) => a.balance - b.balance,
  },
];

const noneActiveDaysRender = (text, row) => {
  const last = moment(text);
  const now = moment(moment(), dateFormat);
  const diff = now.diff(last, 'days');
  let color = Colors.green;
  if (diff < 7) {
    color = Colors.green;
  } else if (diff < 15) {
    color = Colors.geekBlue;
  } else if (diff < 30) {
    color = Colors.volcano;
  } else {
    color = Colors.red;
  }

  let context = diff + '天';

  if (text === row.enrollDate) {
    color = Colors.red;
    context = '一次课没有上';
  }

  if (row.student_school_id.includes('prac')) {
    color = Colors.grey;
    context = '练琴';
  }

  return <Tag color={color}>{context}</Tag>;
};

export default {
  columns_admin,
  columns_admin_core,
  columns_user,
};
