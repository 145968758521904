const menuObj = [
  {
    title: 'Home',
    key: '/home',
  },
  {
    title: '登记',
    key: '/form',
  },
  {
    icon: '',
    title: '登记/学生登记',
    key: '/form/student',
  },
  {
    icon: '',
    title: '登记/报课登记',
    key: '/form/course',
  },
  {
    icon: '',
    title: '登记/付款登记',
    key: '/form/transaction',
  },
  {
    icon: '',
    title: '登记/上课登记',
    key: '/form/feedback',
  },
  {
    icon: '',
    title: '登记/代办事项',
    key: '/form/todolist',
  },

  {
    title: '表格',
    key: '/table',
  },
  {
    icon: '',
    title: '表格/报课表格',
    key: '/table/enrollment',
  },
  {
    icon: '',
    title: '表格/转账表格',
    key: '/table/transaction',
  },
  {
    icon: '',
    title: '表格/上课表格',
    key: '/table/feedback',
  },
  {
    title: '数据',
    key: '/charts',
  },
  {
    icon: '',
    title: '画像/公司',
    key: '/charts/company',
  },
  {
    icon: '',
    title: '画像/老师',
    key: '/charts/teacher',
  },
  {
    title: '学生信息',
    key: '/student/',
  },
];

const pathNameTransform = (pathName) => {
  const regex = /\/student\//;
  const target = menuObj.filter(
    (item) => item.key === pathName || item.key.match(regex)
  );
  return target[0] ? target[0].title : null;
};

export default { pathNameTransform };
