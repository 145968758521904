import moment from 'moment';

/**
 * dateFormat 固定不能修改
 */
export const dateFormat = 'YYYY/MM/DD';

export default {
  formatDate(time) {
    if (!time) return '';
    let date = new Date(time);
    return (
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    );
  },
  toLocalTime(uctTime) {
    let stillUtc = moment.utc(uctTime).toDate();
    return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
  },
};

export const getUnixDateFromNowNum = (seconds) =>
  moment.unix(seconds).fromNow('d').split(' ')[0];

export const transDay = (num) => {
  let day = '';
  switch (num) {
    case 3:
      day = 'Wed';
      break;
    case 4:
      day = 'Thu';
      break;
    case 5:
      day = 'Fri';
      break;
    case 6:
      day = 'Sat';
      break;
    case 0:
      day = 'Sun';
      break;
    default:
      break;
  }
  return day;
};
