import React from 'react';
import { Form, AutoComplete, Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Layout from './layout';
// const FormItem = Form.Item;
const Option = Select.Option;

const defaultRules = [
  {
    required: true,
    message: 'id不能为空',
  },
];

const constructName = (item) => {
  // console.log(item.enname, item.cnname);
  let enname = item.enname;
  let cnname = item.cnname;
  let student_school_id = item.student_school_id;
  if (typeof enname === 'undefined') enname = 'No English Name';
  if (typeof cnname === 'undefined') cnname = 'No Chinese Name';
  if (typeof student_school_id === 'undefined')
    student_school_id = 'No Shool Id';

  let value = enname.concat(' ', cnname, ' ', student_school_id);
  return value;
};

const StudentFormItem = (props) => {
  const { fetchStudentList, studentList, form, autoCompleteStyle } = props;
  let layouts = props.formItemLayout
    ? props.formItemLayout
    : Layout.formItemLayout;
  let rules = props.rules ? props.rules : defaultRules;
  let itemLabel = props.itemLabel ? props.itemLabel : '学生';
  itemLabel = itemLabel === 'NONE' ? null : itemLabel;

  const option = studentList.map((item, i) => {
    return { value: constructName(item), key: item._id };
  });

  return (
    <Form.Item
      label={itemLabel}
      {...layouts}
      name='student'
      rules={rules}
      form={form}
      // onChange={console.log('form item change: ', form.getFieldValue())}
    >
      {
        <AutoComplete
          style={autoCompleteStyle}
          options={option}
          onChange={(e) => fetchStudentList(e)}
          placeholder={
            <>
              <SearchOutlined />
              &nbsp; search students on the system
            </>
          }
          onSelect={(v, o) => {
            // console.log('auto complete: ', v, o, form);
            form.setFieldsValue({ student: v, student_id: o.key });
            // console.log('set fields: ', form.getFieldValue());
          }}
        />
      }
    </Form.Item>
  );
};

export default StudentFormItem;
