import React from 'react';
import { Form, Select } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;
const Option = Select.Option;

const rules = [
  {
    required: true,
    message: '支付方法不能为空',
  },
];

const PaymentMethodFormItem = (props) => {
  return (
    <FormItem
      label='支付方法'
      {...Layout.formItemLayout}
      name='method'
      rules={rules}
    >
      {
        <Select mode='single'>
          <Option value='TRANSFER'>TRANSFER</Option>
          <Option value='SQUARE'>SQUARE</Option>
          <Option value='CASH'>CASH</Option>
          <Option value='ALIPAY'>ALIPAY</Option>
          <Option value='WECHAT'>WECHAT</Option>
          <Option value='EFTPOS'>EFTPOS</Option>
        </Select>
      }
    </FormItem>
  );
};

export default PaymentMethodFormItem;
