import React from 'react';
import echarts from 'echarts/lib/echarts';
import ReactEcharts from 'echarts-for-react';
import Bar from '../../../config/charts/Bar';
import echartTheme from '../../../config/echartTheme';

const AttendanceBar = (props) => {
  const input = props.data;
  echarts.registerTheme('theme', echartTheme);
  return (
    <>
      {console.log('input from Attendance Bar is:', input)}
      <ReactEcharts
        option={Bar.getOptionBar(input)}
        theme={'theme'}
        lazyUpdate={true}
        style={{ height: 280 }}
      />
    </>
  );
};

export default AttendanceBar;
