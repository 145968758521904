import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'antd';
import AddTodo from '../../components/todolist/AddTodo';
import ShowTodos from '../../components/todolist/ShowTodos';
import { addTodo, removeTodo, toggleTodo } from './store/action';

const Todos = (props) => {
  // console.log('Todo props is: ', props);
  const dispatch = useDispatch();
  const todos = useSelector((state) => state.todos);

  const showTodoCbs = useMemo(() => {
    return bindActionCreators(
      {
        removeTodo,
        toggleTodo,
      },
      dispatch
    );
  }, []);

  const addTodoCbs = useMemo(() => {
    return bindActionCreators(
      {
        addTodo,
      },
      dispatch
    );
  }, []);

  return (
    <Card>
      <AddTodo {...addTodoCbs} />
      <ShowTodos {...showTodoCbs} todos={todos} />
    </Card>
  );
};

export default Todos;
