import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EnrollStudentComponent from '../../components/form/EnrollStudent';
import { Card } from 'antd';
import {
  redirectToHome,
  redirectToCourse,
  postEnrollStudent,
  setStudentEnrollModelVisible,
} from './store/enrollmentAction';

// 新生注册 or 学生再次enroll
const EnrollmentStudentPage = () => {
  const dispatch = useDispatch();
  const isStudentModelVisible = useSelector(
    (state) => state.isStudentModelVisible
  );
  const history = useHistory();

  const enrollStudentCbs = useMemo(() => {
    return bindActionCreators(
      {
        postEnrollStudent,
        setStudentEnrollModelVisible,
        redirectToHome,
        redirectToCourse,
      },
      dispatch
    );
  }, []);

  return (
    <Card>
      <EnrollStudentComponent
        {...enrollStudentCbs}
        isStudentModelVisible={isStudentModelVisible}
        history={history}
      />
    </Card>
  );
};

export default EnrollmentStudentPage;
