import React from 'react';
import { Steps } from 'antd';
const { Step } = Steps;

const FormSteps = (props) => {
  const { currentStep } = props;
  return (
    <Steps size='small' current={currentStep} style={{ padding: '20px' }}>
      <Step title='登记学生基本信息' />
      <Step title='登记学生报课信息' />
      <Step title='登记学生转账信息' />
    </Steps>
  );
};

export default FormSteps;
