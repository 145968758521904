import React, { useEffect } from 'react';
import { Card, Form } from 'antd';
import Layout from './layout';
import StudentFormItem from './StudentFormItem';
import InputFormItem from './InputFormItem';
import SubmitFormItem from './SubmitFormItem';
import ShowUserDetail from './ShowUserDetail';
import EnrollCourseFormItem from './EnrollCourseFormItem';
import TeacherFormItem from './TeacherFormItem';
import DateFormItem from './DateFormItem';
import CourseTypeSingleFormItem from './CourseTypeSingleFormItem';
import dataTransform from '../../../util/dataTransform';

const Feedback = (props) => {
  const [form] = Form.useForm();
  const {
    fetchStudentList,
    studentList,
    fetchEnrollCourseList,
    enrollCourseList,
    fetchEnrollCourseDetail,
    enrollCourseDetail,
    fetchTeacherList,
    teacherList,
    postFeedback,
    fetchOneStudentFeedbackList,
    feedbackDataSource,
    searchDetail,
  } = props;

  useEffect(() => {
    if (form.getFieldValue().enrollment) {
      fetchOneStudentFeedbackList(form.getFieldValue().enrollment);
    }
    return () => {
      console.log('离开页面');
      feedbackDataSource([]);
      searchDetail({});
    };
  }, [form.getFieldValue().enrollment]);

  const handleSubmit = () => {
    console.log('raw feedback data: ', form.getFieldValue());
    let feedbackData = dataTransform.feedbackDataTransform(
      form.getFieldValue()
    );
    console.log('feedbackData: ', feedbackData);
    postFeedback(feedbackData);
    feedbackDataSource([]);
    form.resetFields();
  };

  return (
    <div>
      <Card title='请登记学生上课的内容'>
        <Form layout='horizontal' form={form}>
          <StudentFormItem
            rules={[]}
            form={form}
            fetchStudentList={fetchStudentList}
            studentList={studentList}
            itemStyle={Layout.filterInputStyle}
          />
          <EnrollCourseFormItem
            fetchEnrollCourseList={fetchEnrollCourseList}
            fetchEnrollCourseDetail={fetchEnrollCourseDetail}
            enrollCourseList={enrollCourseList}
            form={form}
          />
          <ShowUserDetail
            label='学生余款'
            displayDetail={enrollCourseDetail}
            displayData='balance'
            prefix='AUD'
          />
          <TeacherFormItem
            form={form}
            teacherList={teacherList}
            fetchTeacherList={fetchTeacherList}
          />
          <CourseTypeSingleFormItem />
          <InputFormItem
            label='上课内容'
            fieldName='content'
            placeholder='请输入上课内容'
            requireMessage='上课内容不能为空'
          />
          <DateFormItem />
          <SubmitFormItem hasModel={false} handleClick={handleSubmit} />
        </Form>
      </Card>
    </div>
  );
};

export default Feedback;
