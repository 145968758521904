import React from 'react';
import { Form, Select } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;
const Option = Select.Option;

const rules = [
  {
    required: true,
    message: '收款人不能为空',
  },
];

const UserListFormItem = (props) => {
  const { fetchUserList, userList } = props;
  return (
    <FormItem
      label='收款人'
      {...Layout.formItemLayout}
      name='receiver'
      rules={rules}
    >
      {
        <Select mode='single' onFocus={fetchUserList}>
          {userList.map((item, i) => {
            return (
              <Option value={item._id} key={item._id}>
                {item.cnname}
              </Option>
            );
          })}
        </Select>
      }
    </FormItem>
  );
};

export default UserListFormItem;
