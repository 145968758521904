import React, { useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Tabs } from 'antd';

import enrollsColumns from '../../../config/enrollsColumns';
import studentTransactionColumn from '../../../config/studentTransactionColumn';

import {
  toggleBasicEdit,
  fetchStudentBasic,
  editStudentBasic,
  setStudentBasicDataUpdate,
  fetchEnrollCourseList,
  fetchStudentTransactionList,
} from './store/studentAction';
import {
  fetchOneStudentFeedbackList,
  feedbackDataSource,
  fetchAttendances,
} from '../table/store/tableAction';
import { fetchEnrollCourseList as getCourseListOfStudent } from '../form/store/enrollmentAction';

import Basic from '../../components/student/Basic';
import Enrolls from '../../components/student/Enrolls';
import Transactions from '../../components/student/Transactions';
import Feedbacks from '../../components/student/Feedbacks';
import Attendance from '../../components/student/Attendance';
import feedbackTableColumn from '../../../config/feedbackTableColumn';
import AttendanceOneStudentColumn from '../../../config/AttendanceOneStudentColumn';

const TabPane = Tabs.TabPane;

const StudentPage = (props) => {
  const dispatch = useDispatch();
  const isBasicEdit = useSelector((state) => state.isBasicEdit);
  const studentObj = useSelector((state) => state.studentObj);
  const isDataUpdate = useSelector((state) => state.isStudentBasicDataUpdate);
  const isLoading = useSelector((state) => state.isTableLoading);
  const attendanceList = useSelector((state) => state.attendanceList);
  const studentEnrollCourseList = useSelector(
    (state) => state.studentEnrollCourseList
  );
  const studentTransactionList = useSelector(
    (state) => state.studentTransactionList
  );
  const oneStudentFeedbackList = useSelector(
    (state) => state.oneStudentFeedbackList
  );
  const enrollCourseList = useSelector((state) => state.enrollCourseList);

  const BasicCbs = useMemo(() => {
    return bindActionCreators(
      {
        toggleBasicEdit,
        fetchStudentBasic,
        editStudentBasic,
        setStudentBasicDataUpdate,
      },
      dispatch
    );
  }, [dispatch]);

  const EnrollCourseCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchEnrollCourseList,
      },
      dispatch
    );
  }, []);

  const TransactionCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchStudentTransactionList,
      },
      dispatch
    );
  }, []);

  const FeedbackCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchOneStudentFeedbackList,
        getCourseListOfStudent,
        feedbackDataSource,
      },
      dispatch
    );
  }, []);

  const AttendanceCbs = useMemo(() => {
    return bindActionCreators({ fetchAttendances }, dispatch);
  }, []);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
  });

  return (
    <Card>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='基本信息' key='1'>
          <Basic
            {...BasicCbs}
            isBasicEdit={isBasicEdit}
            studentObj={studentObj}
            isDataUpdate={isDataUpdate}
          />
        </TabPane>
        <TabPane tab='报课信息' key='2'>
          <Enrolls
            {...EnrollCourseCbs}
            columns={enrollsColumns.columns}
            enrollList={studentEnrollCourseList}
          />
        </TabPane>
        <TabPane tab='转账信息' key='3'>
          <Transactions
            {...TransactionCbs}
            columns={studentTransactionColumn.columns}
            transactions={studentTransactionList}
          />
        </TabPane>
        <TabPane tab='上课信息' key='4'>
          <Feedbacks
            {...FeedbackCbs}
            isLoading={isLoading}
            studentObj={studentObj}
            oneStudentFeedbackList={oneStudentFeedbackList}
            enrollCourseList={enrollCourseList}
            columns={feedbackTableColumn.columns}
          />
        </TabPane>
        <TabPane tab='签到信息' key='5'>
          <Attendance
            {...AttendanceCbs}
            isLoading={isLoading}
            attendanceList={attendanceList}
            studentObj={studentObj}
            pagination={pagination}
            columns={AttendanceOneStudentColumn.columns}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default StudentPage;
