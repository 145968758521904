export const ADD = 'ADD';
export const REMOVE = 'DELETE';
export const TOGGLE = 'TOGGLE';

let idSeq = Date.now();

export function addTodo(text) {
  return {
    type: ADD,
    payload: {
      id: ++idSeq,
      text,
      complete: false,
    },
  };
}

export function removeTodo(id) {
  return {
    type: REMOVE,
    payload: id,
  };
}

export function toggleTodo(id) {
  return {
    type: TOGGLE,
    payload: id,
  };
}
