import React from 'react';
import { Form, Input } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;

const InputFormItem = (props) => {
  const { label, fieldName, placeholder, requireMessage, form } = props;
  let { type } = props;
  let isDisabled = props.isDisabled || false;
  if (typeof type === 'undefined') type = 'string';

  const rules = [
    {
      required: typeof requireMessage !== 'undefined',
      message: requireMessage,
    },
  ];

  return (
    <FormItem
      label={label}
      {...Layout.formItemLayout}
      name={fieldName}
      rules={rules}
    >
      {<Input type={type} placeholder={placeholder} disabled={isDisabled} />}
    </FormItem>
  );
};

export default InputFormItem;
