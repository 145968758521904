import moment from 'moment';

const columns = [
  {
    title: '课程名字',
    dataIndex: 'courseName',
    key: 'courseName',
  },
  {
    title: '接收价格',
    dataIndex: 'total_paid',
    key: 'total_paid',
    editable: true,
    responsive: ['lg'],
  },
  {
    title: '货币',
    dataIndex: 'currency',
    key: 'currency',
    responsive: ['lg'],
  },
  {
    title: '支付方式',
    dataIndex: 'method',
    key: 'method',
    responsive: ['lg'],
  },
  {
    title: '总计澳币',
    dataIndex: 'total_aud',
    key: 'total_aud',
  },
  {
    title: '接收人',
    dataIndex: 'receiverName',
    key: 'receiverName',
  },
  {
    title: '付款日期',
    dataIndex: 'transactionDate',
    key: 'transactionDate',
  },
  {
    title: '备注',
    dataIndex: 'comments',
    key: 'comments',
    ellipsis: true,
  },
];

export default { columns };
