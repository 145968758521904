import React from 'react';
import { Form, Select } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;
const Option = Select.Option;

const defaultRules = [
  {
    required: true,
    message: '老师不能为空',
  },
];

const TeacherFormItem = (props) => {
  const { form, fetchTeacherList, teacherList, itemStyle } = props;
  let layouts = props.formItemLayout
    ? props.formItemLayout
    : Layout.formItemLayout;
  let rules = props.rules ? props.rules : defaultRules;
  let label = props.label ? props.label : '老师';
  let name = props.name ? props.name : 'teacher';
  return (
    <FormItem label={label} {...layouts} name={name} rules={rules} form={form}>
      {
        <Select
          mode='single'
          onFocus={fetchTeacherList}
          style={itemStyle}
          placeholder='老师选择'
          allowClear
          onSelect={(v) => {
            console.log('auto complete: ', v);
            form.setFieldsValue({ name: v });
          }}
        >
          {teacherList.map((item, i) => {
            return (
              <Option value={item._id} key={item._id}>
                {item.cnname}
              </Option>
            );
          })}
        </Select>
      }
    </FormItem>
  );
};

export default TeacherFormItem;
