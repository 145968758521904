import React, { useEffect } from 'react';
import { Table, Skeleton } from 'antd';
import EnrollmentTableStatistic from './EnrollmentTableStatistic';
import '../../../style/table.less';
import LocalStorageService from '../../../services/localStorageService';

const EnrollmentTable = (props) => {
  const {
    isLoading,
    search,
    enrollmentList,
    enrollmentListTotal,
    enrollmentStatistic,
    columns,
    coreColumns,
    isCoreColumns,
    pagination,
    setPagination,
    getQuery,
  } = props;

  const displayColumns = !isCoreColumns ? columns : coreColumns;

  useEffect(() => {
    search(pagination.current, pagination.pageSize, getQuery());
  }, [pagination]);

  const handleTableChange = ({ current, pageSize, total }) => {
    console.log('pagination', pagination);
    setPagination({ current, pageSize });
  };
  return isLoading ? (
    <Skeleton active />
  ) : (
    <Table
      dataSource={enrollmentList}
      columns={displayColumns}
      pagination={{
        ...pagination,
        total: enrollmentListTotal,
        position: ['topLeft'],
      }}
      onChange={handleTableChange}
      summary={(pageData) => {
        // console.log('pageData: ', pageData);
        const totalEnrollPrice = enrollmentStatistic.totalEnrollPrice;
        const totalBalance = enrollmentStatistic.totalBalance;

        return LocalStorageService.getCurrentRole() === 2 ? null : (
          <EnrollmentTableStatistic
            totalEnrollPrice={totalEnrollPrice}
            totalBalance={totalBalance}
          />
        );
      }}
    />
  );
};

export default EnrollmentTable;
