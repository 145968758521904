import axios from '../api/axios';
import { baseURL, localURL } from '../config/url';
import localStorageService from './localStorageService';

const enrollStudentUrl = baseURL + '/students';
const enrollCourseUrl = baseURL + '/enrollments';
const enrollCourseBalance = baseURL + '/enrollments/balance';
const enrollCourseDetail = baseURL + '/enrollments/detail';
const enrollTransactionUrl = baseURL + '/transactions';
const feedbackUrl = baseURL + '/feedbacks';

const enrollStudentRequest = async (data) => {
  // console.log('enroll student url: ', enrollStudentUrl);
  // console.log('auth: ', localStorageService.getAuth());
  // console.log('param: ', data);
  return axios.post(`${enrollStudentUrl}`, data);
};

const enrollCourseRequest = async (data) => {
  // console.log('enroll student url: ', enrollCourseUrl);
  // console.log('auth: ', localStorageService.getAuth());
  // console.log('param: ', data);
  return axios.post(`${enrollCourseUrl}`, data);
};

const enrollTransactionRequest = async (data) => {
  // console.log('enroll transaction url: ', enrollTransactionUrl);
  return axios.post(`${enrollTransactionUrl}`, data);
};

const makeFeedback = async (feedback) => {
  return axios.post(feedbackUrl, feedback);
};

async function searchStudentRequest(url) {
  axios.get(url).then((res) => {
    // console.log('get result: ', res);
    return res;
  });
}

/**
 * archive
 */
async function searchBalance(enrollmentId) {
  const url = enrollCourseBalance + `/${enrollmentId}`;
  return axios.get(url);
}

async function searchDetail(enrollmentId) {
  const url = enrollCourseDetail + `/${enrollmentId}`;
  return axios.get(url);
}

const queryStudent = async (query) => {
  let url = baseURL + `/students?q=${query}`;
  return await axios.get(url);
};

const queryUser = async () => {
  let url = baseURL + '/users';
  return await axios.get(url);
};

const queryTeacher = async () => {
  let url = baseURL + '/users/teachers';
  return await axios.get(url);
};

const queryAdvisor = async () => {
  let url = baseURL + '/users/advisors';
  return await axios.get(url);
};

const queryCourse = async () => {
  let url = baseURL + '/courses';
  return await axios.get(url);
};

const queryEnrollCourse = async (id) => {
  let url = baseURL + `/enrollments/student/${id}`;
  return await axios.get(url);
};

export default {
  enrollStudentRequest,
  enrollCourseRequest,
  enrollTransactionRequest,
  makeFeedback,
  searchStudentRequest,
  searchBalance,
  searchDetail,
  queryStudent,
  queryUser,
  queryTeacher,
  queryCourse,
  queryAdvisor,
  queryEnrollCourse,
};
