import axios from '../api/axios';
import { baseURL, localURL } from '../config/url';
import LocalStorageService from './localStorageService';

const feedbackByEnrollmentUrl = baseURL + '/enrollments/feedbacks/';
const attendanceUrl = baseURL + '/attendance';
const enrollmentsUrl = baseURL + '/enrollments';
const transactionsUrl = baseURL + '/transactions';
const feedbackDetailsUrl = baseURL + '/feedbacks';

const searchFeedbacks = async (enrollmentId) => {
  const url = feedbackByEnrollmentUrl + `${enrollmentId}`;
  return axios.get(url);
};

const deleteFeedbacks = async (id) => {
  const url = feedbackDetailsUrl + `/${id}`;
  console.log(
    'The user call delete feedbacks is: ',
    LocalStorageService.getCurrentUser()
  );
  const data = { user: LocalStorageService.getCurrentUser() };
  return axios.delete(url, { data });
};

const getEnrollments = async (page, per_page, query) => {
  if (typeof page === 'number') {
    //?page=2&per_page=1
    let url = enrollmentsUrl + `/?page=${page}&per_page=${per_page}`;
    if (query) url = url + query;
    // console.log('url is: ', url);
    return axios.get(url);
  }
  return axios.get(enrollmentsUrl);
};

const getAttendances = async (page, per_page, query) => {
  if (typeof page === 'number') {
    let url = attendanceUrl + `/?page=${page}&per_page=${per_page}`;
    if (query) url = url + query;
    console.log('url is: ', url);
    return axios.get(url);
  }
  return axios.get(attendanceUrl);
};

const getFeedbackDetails = async (page, per_page, query) => {
  if (typeof page === 'number') {
    //?page=2&per_page=1
    let url = feedbackDetailsUrl + `/?page=${page}&per_page=${per_page}`;
    if (query) url = url + query;
    // console.log('url is: ', url);
    return axios.get(url);
  }
  return axios.get(enrollmentsUrl);
};

const getTransactionSummary = async (page, per_page, query) => {
  if (typeof page === 'number') {
    let url = transactionsUrl + `/?page=${page}&per_page=${per_page}`;
    if (query) url = url + query;
    // console.log('url is: ', url);
    return axios.get(url);
  }
  return axios.get(enrollmentsUrl);
};

const getTransactionByStudent = async (studentId) => {
  const url = transactionsUrl + `/student/${studentId}`;
  return axios.get(url);
};

export default {
  searchFeedbacks,
  deleteFeedbacks,
  getEnrollments,
  getAttendances,
  getTransactionByStudent,
  getFeedbackDetails,
  getTransactionSummary,
};
