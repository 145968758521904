const getOptionBar = (input) => {
  let option = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: input.legend,
    },
    xAxis: {
      data: input.x,
    },
    yAxis: {
      type: 'value',
    },
    series: input.data,
  };
  return option;
};

export default { getOptionBar };
