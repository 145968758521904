import React from 'react';
import '../../style/common.less';
import { Row, Col, Layout } from 'antd';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import NavLeft from '../components/common/NavLeft';

const { Content } = Layout;

const Admin = (props) => {
  return (
    <Row className='container'>
      <Col xl={2} lg={2} md={5} sm={5} xs={5} className='nav-left'>
        <NavLeft />
      </Col>
      <Col xl={22} lg={22} md={19} sm={19} xs={19} className='main'>
        <Header logState={props.logState} />
        <Row className='content'>
          <Content>{props.children}</Content>
        </Row>
        <Footer />
      </Col>
    </Row>
  );
};

export default Admin;
