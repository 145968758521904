import React from 'react';
import { Form, DatePicker } from 'antd';
import Layout from './layout';

const FormItem = Form.Item;

const rules = [
  {
    required: true,
    message: '日期不能为空',
  },
];

const DateFormItem = ({
  label = '选择日期',
  isDisabled = false,
  isRequire = true,
}) => {
  const fieldRule = isRequire ? rules : undefined;

  return (
    <FormItem
      label={label}
      {...Layout.formItemLayout}
      name='datepick'
      rules={fieldRule}
    >
      {<DatePicker disabled={isDisabled} />}
    </FormItem>
  );
};

export default DateFormItem;
