import axios from '../api/axios';
import { baseURL, localURL } from '../config/url';

const editStudentBasicUrl = baseURL + '/students';

const getStudentBasic = async (studentId) => {
  const url = editStudentBasicUrl + `/${studentId}`;
  return axios.get(url);
};

const editStudentBasic = async (studentId, studentData) => {
  const url = editStudentBasicUrl + `/${studentId}`;
  return axios.patch(url, studentData);
};

export default { editStudentBasic, getStudentBasic };
