const formItemLayout = {
  labelCol: {
    xs: 24,
    sm: 4,
  },
  wrapperCol: {
    xs: 24,
    sm: 12,
  },
};

const offsetLayout = {
  wrapperCol: {
    xs: 24,
    sm: {
      span: 12,
      offset: 4,
    },
  },
};

const cardStyle = { margin: '50px' };

const filterInputStyle = { width: 180, margin: '0 8px', textAlign: 'center' };

const corseTypeStyle = { width: 170, margin: '0 8px' };

const homeInputStyle = { width: 180, margin: '0 8px' };

const autoCompleteStyle = { width: 200, margin: '0 8px' };

export default {
  formItemLayout,
  offsetLayout,
  cardStyle,
  filterInputStyle,
  homeInputStyle,
  autoCompleteStyle,
  corseTypeStyle,
};
