import React, { useRef } from 'react';
import { Card, Form, Input, Icon, Button } from 'antd';

const LoginComponent = (props) => {
  console.log('props in login component is: ', props);
  const { requestLogin, history } = props;
  const inputName = useRef();
  const inputPassword = useRef();

  const handleLogin = () => {
    const name = inputName.current.state.value;
    const password = inputPassword.current.state.value;
    console.log('handleLogin: ', name, password);
    if (name != null && password != null) {
      requestLogin({
        name,
        password,
        history,
      });
    }
    return;
  };

  return (
    <Card title='登录' style={{ marginTop: 10 }}>
      <Form style={{ width: 300 }}>
        <Form.Item>
          <Input
            prefix={<Icon type='user' />}
            ref={inputName}
            placeholder='请输入用户名'
          />
        </Form.Item>
        <Form.Item>
          <Input
            prefix={<Icon type='lock' />}
            ref={inputPassword}
            type='password'
            placeholder='请输入密码'
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' onClick={handleLogin}>
            登录
          </Button>
          <a href='/register' style={{ marginLeft: 50 }}>
            注册
          </a>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default LoginComponent;
