import React from 'react';
import echarts from 'echarts/lib/echarts';
import ReactEcharts from 'echarts-for-react';
import PieMock from '../../../config/charts/Pie';
import echartTheme from '../../../config/echartTheme';

const SalesCategoryPie = (props) => {
  const input = props.data;
  echarts.registerTheme('theme', echartTheme);
  return (
    <>
      <ReactEcharts
        option={PieMock.getOptionPie(input)}
        theme={'theme'}
        lazyUpdate={true}
        style={{ height: 280 }}
      />
    </>
  );
};

export default SalesCategoryPie;
