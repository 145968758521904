import React, { useMemo, useState } from 'react';
import { Card, Form } from 'antd';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { dateFormat } from '../../../util/util';
import Query from '../../../util/query';
import LocalStorageService from '../../../services/localStorageService';
import EnrollmentTableColumn from '../../../config/enrollmentTableColumn';
import EnrollmentTable from '../../components/table/EnrollmentTable';
import FilterForm from '../../components/form/FilterForm';
import { fetchEnrollments as search } from './store/tableAction';
import {
  fetchStudentList,
  fetchTeacherList,
  fetchAdvisorList,
} from '../form/store/enrollmentAction';

const displayColumns = LocalStorageService.isAdmin
  ? EnrollmentTableColumn.columns_admin
  : EnrollmentTableColumn.columns_user;

const displayCoreColumns = LocalStorageService.isAdmin
  ? EnrollmentTableColumn.columns_admin_core
  : EnrollmentTableColumn.columns_user;

const displayEnrollment = LocalStorageService.isAdmin ? 90 : 300;
const displayPerPage = 100;

const defaultInitialValue = [
  moment(moment().subtract(displayEnrollment, 'days'), dateFormat),
  moment(moment(), dateFormat),
];

const EnrollmentTablePage = () => {
  const [form] = Form.useForm();
  const [isCoreColumns, setIsCoreColumns] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: displayPerPage,
  });
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isTableLoading);
  const enrollmentList = useSelector((state) => state.enrollmentList);
  const enrollmentListTotal = useSelector((state) => state.enrollmentListTotal);
  const enrollmentStatistic = useSelector((state) => state.enrollmentStatistic);
  const studentList = useSelector((state) => state.studentList);
  const teacherList = useSelector((state) => state.teacherList);
  const advisorList = useSelector((state) => state.advisorList);

  function getQuery() {
    console.log('get query', form.getFieldValue());
    let query = Query.constructQueryForEnrollment(form.getFieldValue());
    console.log('query in filter form is: ', query);
    return query;
  }

  const EnrollmentTableQueryCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchStudentList,
        fetchTeacherList,
        fetchAdvisorList,
        search,
      },
      dispatch
    );
  }, []);

  const EnrollmentTableCbs = useMemo(() => {
    return bindActionCreators(
      {
        search,
      },
      dispatch
    );
  }, []);

  return (
    <div>
      <Card style={{ marginTop: 10 }}>
        <FilterForm
          {...EnrollmentTableQueryCbs}
          form={form}
          getQuery={getQuery}
          studentList={studentList}
          teacherList={teacherList}
          advisorList={advisorList}
          pagination={pagination}
          isCoreColumns={isCoreColumns}
          setIsCoreColumns={setIsCoreColumns}
          defaultInitialValue={defaultInitialValue}
        />
      </Card>
      <Card style={{ marginTop: 10 }}>
        <EnrollmentTable
          {...EnrollmentTableCbs}
          isLoading={isLoading}
          enrollmentList={enrollmentList}
          enrollmentListTotal={enrollmentListTotal}
          enrollmentStatistic={enrollmentStatistic}
          columns={displayColumns}
          coreColumns={displayCoreColumns}
          isCoreColumns={isCoreColumns}
          pagination={pagination}
          setPagination={setPagination}
          defaultInitialValue={defaultInitialValue}
          getQuery={getQuery}
        />
      </Card>
    </div>
  );
};

export default EnrollmentTablePage;
