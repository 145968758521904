import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';

import EnrollCourseFormItem from '../form/EnrollCourseFormItem';

const EnrollSelect = (props) => {
  /**
   * 这里的useState 主要是解决选择课程需同步的问题
   */
  const [isTrigger, setIsTrigger] = useState(false);
  const [form] = Form.useForm();
  const {
    studentObj,
    getCourseListOfStudent,
    enrollCourseList,
    fetchOneStudentFeedbackList,
    feedbackDataSource,
  } = props;

  const studentId = useParams().id;
  useEffect(() => {
    // console.log('default enrollment: ', studentObj.currentEnrollmentName);
    // console.log('use effect in enroll select: ', form.getFieldValue());
    if (!form.getFieldValue().enrollment && studentObj.currentEnrollment) {
      fetchOneStudentFeedbackList(studentObj.currentEnrollment);
    } else if (form.getFieldValue().enrollment) {
      fetchOneStudentFeedbackList(form.getFieldValue().enrollment);
    }
    return () => feedbackDataSource([]);
  }, [form.getFieldValue().enrollment]);

  return (
    <Form layout='horizontal' form={form} initialValues={{ enrollment: '' }}>
      <EnrollCourseFormItem
        label={'从报课历史选择'}
        rules={false}
        fetchEnrollCourseList={getCourseListOfStudent}
        enrollCourseList={enrollCourseList}
        form={form}
        inputId={studentId}
        isTrigger={isTrigger}
        setIsTrigger={setIsTrigger}
      />
    </Form>
  );
};

export default EnrollSelect;
