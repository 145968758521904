import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card } from 'antd';
import EnrollCourse from '../../components/form/EnrollCourse';
import {
  fetchStudentList,
  fetchTeacherList,
  fetchCoursesList,
  fetchAdvisorList,
  setCourseEnrollModeVisible,
  redirectToHome,
  redirectToTransaction,
  postEnrollCourse,
} from './store/enrollmentAction';

const EnrollCoursePage = () => {
  const studentList = useSelector((state) => state.studentList);
  const teacherList = useSelector((state) => state.teacherList);
  const courseList = useSelector((state) => state.courseList);
  const advisorList = useSelector((state) => state.advisorList);
  const isCourseEnrollModelVisible = useSelector(
    (state) => state.isCourseEnrollModelVisible
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const EnrollCourseCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchStudentList,
        fetchTeacherList,
        fetchCoursesList,
        fetchAdvisorList,
        setCourseEnrollModeVisible,
        redirectToHome,
        redirectToTransaction,
        postEnrollCourse,
      },
      dispatch
    );
  }, []);

  return (
    <div>
      <Card>
        <EnrollCourse
          {...EnrollCourseCbs}
          history={history}
          studentList={studentList}
          teacherList={teacherList}
          courseList={courseList}
          advisorList={advisorList}
          isCourseEnrollModelVisible={isCourseEnrollModelVisible}
        />
      </Card>
    </div>
  );
};

export default EnrollCoursePage;
