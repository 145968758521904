import React from 'react';
import { Card, Col, Row, Statistic, Divider } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const cardStyle = { margin: '12px' };
const dividerStyle = { color: '#8C8C8C' };

const SalesCard = (props) => {
  const { title, value, subTitle, subValue } = props;
  return (
    <>
      <Card bordered={true} style={cardStyle} hoverable>
        <Statistic title={title} value={value} />
        <Divider />
        <div style={dividerStyle}>
          {subTitle}
          {subValue > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          {subValue + '%'}
        </div>
      </Card>
    </>
  );
};

export default SalesCard;
