import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tag } from 'antd';

const tagColor = '#36cfc9';

const columns = [
  {
    title: '名字',
    dataIndex: 'studentName',
    key: 'studentName',
    render: (text, record, index) => {
      return <NavLink to={`/student/${record.student}`}>{text}</NavLink>;
    },
  },
  {
    title: '课程名字',
    dataIndex: 'courseName',
    key: 'courseName',
  },
  {
    title: '试听老师',
    dataIndex: 'trialTeacher',
    key: 'trialTeacher',
  },
  {
    title: '顾问',
    dataIndex: 'advisor',
    key: 'advisor',
  },
  {
    title: '总计澳币',
    dataIndex: 'total_aud',
    key: 'total_aud',
    render: (text) => total_price_render(text),
  },
  {
    title: '接收价格',
    dataIndex: 'total_paid',
    key: 'total_paid',
    editable: true,
    responsive: ['lg'],
  },
  {
    title: '货币',
    dataIndex: 'currency',
    key: 'currency',
    responsive: ['lg'],
  },
  {
    title: '支付方式',
    dataIndex: 'method',
    key: 'method',
    responsive: ['lg'],
  },
  {
    title: '接收人',
    dataIndex: 'receiverName',
    key: 'receiverName',
    responsive: ['lg'],
  },
  {
    title: '付款日期',
    dataIndex: 'transactionDate',
    key: 'transactionDate',
    responsive: ['lg'],
  },
  {
    title: '备注',
    dataIndex: 'comments',
    key: 'comments',
    responsive: ['lg'],
    ellipsis: true,
  },
];

const total_price_render = (text) => {
  let render_color = tagColor;
  if (text < 0) render_color = 'red';
  return <Tag color={render_color}>{text}</Tag>;
};

export default { columns };
