import React from 'react';
import { Table, Tag } from 'antd';
import '../../../style/table.less';
const { Summary } = Table;

const tagColor = '#9254de';

const AttendanceTableStatistic = (props) => {
  const { attendanceStatistic } = props;
  return (
    <>
      <Summary.Row>
        <Summary.Cell>出勤总人数</Summary.Cell>
        <Summary.Cell colSpan={1}>
          <Tag color={tagColor}>{attendanceStatistic}</Tag>
        </Summary.Cell>
      </Summary.Row>
    </>
  );
};

export default AttendanceTableStatistic;
