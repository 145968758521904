import React from 'react';
import { Card } from 'antd';

import EnrollSelect from './EnrollSelect';
import FeedbackTable from '../table/FeedbackTable';

const Feedbacks = (props) => {
  const {
    isLoading,
    studentObj,
    getCourseListOfStudent,
    enrollCourseList,
    fetchOneStudentFeedbackList,
    oneStudentFeedbackList,
    feedbackDataSource,
    columns,
  } = props;

  let courseName =
    studentObj.currentEnrollmentName != null
      ? studentObj.currentEnrollmentName
      : '没有进行的课程';
  const courseTitle = '当前课程: ' + courseName;
  return (
    <>
      <Card title={courseTitle}>
        <EnrollSelect
          studentObj={studentObj}
          getCourseListOfStudent={getCourseListOfStudent}
          enrollCourseList={enrollCourseList}
          fetchOneStudentFeedbackList={fetchOneStudentFeedbackList}
          feedbackDataSource={feedbackDataSource}
        />
      </Card>
      <Card title='学生上课记录' style={{ margin: '10px 0' }}>
        <FeedbackTable
          isLoading={isLoading}
          oneStudentFeedbackList={oneStudentFeedbackList}
          feedbackTableColumn={columns}
        />
      </Card>
    </>
  );
};

export default Feedbacks;
