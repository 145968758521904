import moment from 'moment';
import localStorageService from '../services/localStorageService';
import Util from '../util/util';

const createBy = localStorageService.getCurrentUser();
const dateFormat = 'YYYY/MM/DD';
export const stringToMoment = (dateString) => moment(dateString, dateFormat);

const merge = (arr1, arr2) => {
  return arr1.map((number, index) => {
    return number + arr2[index];
  });
};

const enrollStudentDataTransform = (rawData) => {
  const { datepick } = rawData;
  let createByInfo = createBy ?? localStorageService.getCurrentUser();
  const studentDOB = datepick ? datepick.format(dateFormat) : null;
  return { ...rawData, studentDOB, createBy: createByInfo };
};

const enrollmentDateTransform = (rawData) => {
  let { student_id, total_price, datepick, ...rest } = rawData;
  let enrollData = {
    ...rest,
    student: student_id,
    total_price: parseInt(total_price),
    balance: parseInt(total_price),
    enrollDate: datepick ? datepick.format(dateFormat) : null,
    createBy,
  };
  return enrollData;
};

const transactionDataTransform = (rawData) => {
  let {
    currency,
    enrollment,
    method,
    receiver,
    student_id,
    total_aud,
    total_paid,
    comments,
    datepick,
  } = rawData;

  const transactionData = {
    student: student_id,
    enrollment,
    receive_price: { total_paid: parseInt(total_paid), currency, method },
    total_aud: parseInt(total_aud),
    receiver,
    comments,
    transactionDate: datepick ? datepick.format(dateFormat) : null,
    createBy,
  };
  return transactionData;
};

const feedbackDataTransform = (rawData) => {
  let { datepick, student_id, ...rest } = rawData;
  return {
    ...rest,
    student: student_id,
    feedbackDate: datepick ? datepick.format(dateFormat) : null,
    createBy,
  };
};

const enrollmentListDataTransform = (income) => {
  // console.log('enrollmentListDataTransform: ', income);
  let { total, item, totalEnrollPrice, totalBalance } = income;
  let displayData = item.map((enrollment, index) => {
    return {
      key: enrollment._id,
      student_school_id: enrollment.student_school_id,
      studentName: enrollment.studentName,
      feedbackCounter: enrollment.feedbackCounter,
      attendanceCounter: enrollment.attendanceCounter,
      lastActiveDate: enrollment.lastActiveDate,
      courseName: enrollment.courseName,
      teacherName: enrollment.teacherName,
      advisorName: enrollment.advisorName,
      enrollDate: enrollment.enrollDate,
      total_price: enrollment.total_price,
      balance: enrollment.balance,
      student: enrollment.student,
      comments: enrollment.comments,
      paid: enrollment.total_price - enrollment.balance,
    };
  });
  return { total, displayData, totalEnrollPrice, totalBalance };
};

const attendanceListDataTransform = (income) => {
  console.log('attendanceListDataTransform: ', income);
  let { total, item } = income;
  let displayData = item.map((attendance, index) => {
    return {
      key: attendance._id,
      student: attendance.student,
      student_school_id: attendance.student_school_id,
      attendTime: attendance.attendTime
        ? attendance.attendTime
        : attendance.attendDate,
      studentName: attendance.cnname,
      courseName: attendance.currentEnrollmentName,
      teacherName: attendance.currentTeacherName,
      advisorName: attendance.currentAdvisorName,
      enrollDate: attendance.currentEnrollmentDate,
      toNow: attendance.toNow,
      balance: attendance.currentBalance ? attendance.currentBalance : 0,
    };
  });
  return { total, displayData };
};

const studentBasicDataTransform = (income) => {
  const {
    student_school_id,
    other_info,
    cnname,
    enname,
    phone,
    email,
    wechat,
    studentDOB,
    currentEnrollment,
    currentEnrollmentName,
    createBy,
    updatedAt,
  } = income;
  return {
    student_school_id,
    other_info,
    cnname,
    enname,
    phone,
    email,
    wechat,
    studentDOB: stringToMoment(studentDOB),
    currentEnrollment,
    currentEnrollmentName,
    createBy,
    updatedAt: Util.toLocalTime(updatedAt),
  };
};

const studentPatchDataTransform = (income) => {
  const {
    student_school_id,
    other_info,
    datepick,
    cnname,
    enname,
    phone,
    email,
    wechat,
  } = income;
  return {
    student_school_id,
    other_info,
    studentDOB: datepick ? datepick.format(dateFormat) : null,
    cnname,
    enname,
    phone,
    email,
    wechat,
    createBy,
  };
};

const studentEnrollCourseDataTransform = (income) => {
  let displayData = income.map((enrollment, index) => {
    return {
      key: enrollment._id,
      courseName: enrollment.courseName,
      teacherName: enrollment.teacherName,
      advisorName: enrollment.advisorName,
      enrollDate: enrollment.enrollDate,
      total_price: enrollment.total_price,
      balance: enrollment.balance,
      student: enrollment.student,
    };
  });
  return displayData;
};

const studentTransactionDataTransform = (income) => {
  if (income === []) return [];
  let displayData = income.map((item, index) => {
    return {
      key: item._id,
      courseName: item.courseName,
      total_paid: item.receive_price.total_paid,
      currency: item.receive_price.currency,
      method: item.receive_price.method,
      total_aud: item.total_aud,
      receiverName: item.receiverName,
      transactionDate: item.transactionDate,
      comments: item.comments,
    };
  });
  return displayData;
};

const transactionSummaryDataTransform = (income) => {
  if (income === []) return [];
  let { total, totalTransaction, item } = income;
  // console.log('transaction list: ', income);
  let displayData = item.map((transaction, index) => {
    return {
      key: transaction._id,
      student: transaction.student,
      studentName: transaction.studentName,
      courseName: transaction.courseName,
      total_paid: transaction.receive_price.total_paid,
      currency: transaction.receive_price.currency,
      method: transaction.receive_price.method,
      total_aud: transaction.total_aud,
      receiverName: transaction.receiverName,
      transactionDate: transaction.transactionDate,
      comments: transaction.comments,
      advisor:
        transaction.enrollment == null
          ? ''
          : transformTransactionEnrollment(transaction.enrollment).advisorName,
      trialTeacher:
        transaction.enrollment == null
          ? ''
          : transformTransactionEnrollment(transaction.enrollment).trialTeacher,
    };
  });
  // console.log('display data list: ', displayData);
  return { total, totalTransaction, displayData };
};

const transformTransactionEnrollment = (enrollment) => {
  let advisorName = '';
  let trialTeacher = '';

  if (enrollment.advisorName) {
    advisorName = enrollment.advisorName;
  }

  if (enrollment.trialTeacher && enrollment.trialTeacher.cnname) {
    trialTeacher = enrollment.trialTeacher.cnname;
  }
  return { advisorName, trialTeacher };
};

const feedbackDetailDataTransform = (income) => {
  let { total, item } = income;
  let displayData = item.map((feedback, index) => {
    return {
      key: feedback._id,
      student: feedback.student,
      studentName: feedback.studentName,
      teacherName: feedback.teacherName,
      courseName: feedback.courseName,
      courseType: feedback.courseType,
      content: feedback.content,
      feedbackDate: feedback.feedbackDate,
    };
  });
  return { total, displayData };
};

export default {
  merge,
  enrollStudentDataTransform,
  transactionDataTransform,
  enrollmentDateTransform,
  attendanceListDataTransform,
  feedbackDataTransform,
  enrollmentListDataTransform,
  studentBasicDataTransform,
  studentPatchDataTransform,
  studentEnrollCourseDataTransform,
  studentTransactionDataTransform,
  feedbackDetailDataTransform,
  transactionSummaryDataTransform,
};
