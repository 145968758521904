import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import './style.less';

import { fetchStudentList } from '../form/store/enrollmentAction';

import Home from '../../components/home/Home';

const HomePage = () => {
  const dispatch = useDispatch();
  const studentList = useSelector((state) => state.studentList);
  const HomeCbs = useMemo(() => {
    return bindActionCreators({ fetchStudentList }, dispatch);
  }, []);

  return (
    <div className='home-wrap'>
      <Home {...HomeCbs} studentList={studentList} />
    </div>
  );
};

export default HomePage;
