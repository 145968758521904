import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Transaction from '../../components/form/Transaction';
import { Card } from 'antd';
import {
  fetchUserList,
  fetchStudentList,
  fetchEnrollCourseList,
  postEnrollTransaction,
  fetchEnrollCourseDetail,
  searchDetail,
} from './store/enrollmentAction';

// 新生注册 or 学生再次enroll
const TransactionPage = () => {
  const dispatch = useDispatch();
  const studentList = useSelector((state) => state.studentList);
  const enrollCourseList = useSelector((state) => state.enrollCourseList);
  const userList = useSelector((state) => state.userList);
  const enrollCourseDetail = useSelector((state) => state.enrollCourseDetail);
  const history = useHistory();

  const TransactionCbs = useMemo(() => {
    return bindActionCreators(
      {
        fetchUserList,
        fetchStudentList,
        fetchEnrollCourseList,
        fetchEnrollCourseDetail,
        postEnrollTransaction,
        searchDetail,
      },
      dispatch
    );
  }, []);

  return (
    <Card>
      <Transaction
        {...TransactionCbs}
        history={history}
        userList={userList}
        studentList={studentList}
        enrollCourseList={enrollCourseList}
        enrollCourseDetail={enrollCourseDetail}
      />
    </Card>
  );
};

export default TransactionPage;
