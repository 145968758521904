import React from 'react';
import { Card, Form } from 'antd';
import InputFormItem from './InputFormItem';
import SubmitFormItem from './SubmitFormItem';
import FormSteps from './FormSteps';
import dataTransform from '../../../util/dataTransform';
import Layout from './layout';
import InputTextArea from './InputTextArea';
import DateFormItem from './DateFormItem';

const EnrollStudent = (props) => {
  const [form] = Form.useForm();
  const {
    history,
    redirectToHome,
    redirectToCourse,
    postEnrollStudent,
    isStudentModelVisible,
    setStudentEnrollModelVisible,
  } = props;

  function handleSubmit() {
    let userInfo = form.getFieldsValue();
    console.log('** userInfo', userInfo);
    let studentData = dataTransform.enrollStudentDataTransform(userInfo);
    postEnrollStudent(studentData);
  }

  return (
    <div>
      <FormSteps currentStep={0} />
      <Card title='请登记学生基本信息' style={Layout.cardStyle}>
        <Form layout='horizontal' form={form}>
          <InputFormItem
            label='学生学号'
            fieldName='student_school_id'
            placeholder='请输学生学号'
          />
          <InputFormItem
            label='中文名'
            fieldName='cnname'
            placeholder='请输入用户名'
            requireMessage='中文名不能为空'
          />
          <InputFormItem
            label='英文名'
            fieldName='enname'
            placeholder='请输入英文名'
            requireMessage='英文名不能为空'
          />
          <InputFormItem
            label='手机'
            fieldName='phone'
            placeholder='请输入手机号码'
            requireMessage='手机不能为空'
          />
          <InputFormItem
            label='邮箱'
            fieldName='email'
            placeholder='请输入邮箱'
            requireMessage='邮箱不能为空'
          />
          <DateFormItem label={'生日日期'} isRequire={false} />
          <InputTextArea
            label='备注'
            fieldName='other_info'
            placeholder='请输入学生更多信息'
          />
          <SubmitFormItem
            hasModel={true}
            handleSubmit={handleSubmit}
            isModelVisible={isStudentModelVisible}
            setModelVisible={setStudentEnrollModelVisible}
            ToHome={() => redirectToHome(history)}
            handleRedirect={() => redirectToCourse(history)}
            instructions={[
              '成功注册新学生',
              '新学生尚未登记任何课程',
              '是否为学生登记课程？',
            ]}
          />
        </Form>
      </Card>
    </div>
  );
};

export default EnrollStudent;
