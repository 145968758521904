import React, { useState } from 'react';
import { Form, Button, Radio } from 'antd';

import Layout from './layout';
import Global from '../../../util/global';
import StudentFormItem from './StudentFormItem';
import TeacherFormItem from './TeacherFormItem';
import AdvisorFormItem from './AdvisorFormItem';
import RangeDateFormItem from './RangeDateFormItem';
import NonActiveDaysFormItem from './NonActiveDaysFormItem';
import RangeNumberFormItem from './RangeNumberFormItem';
import BrForForm from './BrForForm';

/**
 * 目前这个component在enrollment and attendance都被用到
 */
const FilterForm = (props) => {
  const {
    fetchStudentList,
    studentList,
    teacherList,
    fetchTeacherList,
    advisorList,
    fetchAdvisorList,
    search,
    pagination,
    defaultInitialValue,
    form,
    isCoreColumns,
    setIsCoreColumns,
    getQuery,
  } = props;

  const [radioValue, setRadioValue] = useState(Global.last90);

  /**
   * The request
   */
  function query() {
    let query = getQuery();
    search(pagination.current, pagination.pageSize, query);
    console.log('query', form.getFieldValue());
  }

  function handleSizeChange(e) {
    console.log('handleSizeChange: ', e);
    form.setFieldsValue({ rangeDatePick: e.target.value });
    setRadioValue(e.target.value);
    let query = getQuery();
    search(pagination.current, pagination.pageSize, query);
  }
  function handleDisplayChange(e) {
    // console.log('handleDisplayChange: ', e.target.value);
    setIsCoreColumns(e.target.value);
  }

  function onReset() {
    console.log('reset: ', form.getFieldValue());
    form.resetFields();
    form.setFieldsValue({ rangeDatePick: [] });
    setRadioValue(null);
  }

  return (
    <>
      <Form
        layout='inline'
        form={form}
        initialValues={{ rangeDatePick: Global.last90 }}
      >
        <StudentFormItem
          formItemLayout={{}}
          rules={[]}
          form={form}
          fetchStudentList={fetchStudentList}
          studentList={studentList}
          autoCompleteStyle={Layout.autoCompleteStyle}
        />
        <TeacherFormItem
          formItemLayout={{}}
          rules={[]}
          form={form}
          teacherList={teacherList}
          fetchTeacherList={fetchTeacherList}
          itemStyle={Layout.filterInputStyle}
        />
        <BrForForm />
        <AdvisorFormItem
          formItemLayout={{}}
          rules={[]}
          form={form}
          advisorList={advisorList}
          fetchAdvisorList={fetchAdvisorList}
          itemStyle={Layout.filterInputStyle}
        />
        <BrForForm />
        <RangeDateFormItem form={form} setRadioValue={setRadioValue} />
      </Form>
      <Form
        layout='inline'
        form={form}
        initialValues={{ rangeDatePick: defaultInitialValue }}
      >
        <BrForForm />
        <RangeNumberFormItem />
        <BrForForm />
        <NonActiveDaysFormItem label='活跃' style={Layout.filterInputStyle} />
        <BrForForm />
        <Form.Item>
          <Radio.Group value={radioValue} onChange={(e) => handleSizeChange(e)}>
            <Radio.Button value={Global.today}>今日</Radio.Button>
            <Radio.Button value={Global.thisMonth}>本月</Radio.Button>
            <Radio.Button value={Global.last90}>季度</Radio.Button>
            <Radio.Button value={Global.all}>全部</Radio.Button>
          </Radio.Group>
          <Button type='primary' style={{ margin: '0 20px' }} onClick={query}>
            查询
          </Button>
          <Button htmlType='button' onClick={onReset}>
            重置
          </Button>
        </Form.Item>
        <Form.Item>
          <Radio.Group
            value={isCoreColumns}
            onChange={(e) => handleDisplayChange(e)}
          >
            <Radio.Button value={Global.coreData}>核心</Radio.Button>
            <Radio.Button value={!Global.coreData}>详情</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </>
  );
};

export default FilterForm;
