import React from 'react';
import { Form, Select } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;
const Option = Select.Option;

const EnrollCourseFormItem = (props) => {
  const {
    form,
    fetchEnrollCourseList,
    enrollCourseList,
    inputId,
    fetchEnrollCourseDetail,
  } = props;

  const rules = props.rules
    ? props.rules
    : [
        {
          required: true,
          message: '课程不能为空',
        },
      ];

  const label = props.label ? props.label : '报名的课程';

  function receiveEnrollCourse(inputId) {
    console.log('receive enroll course: ', form.getFieldValue());
    let studentId = inputId ? inputId : form.getFieldValue().student_id;
    if (studentId) {
      console.log('inputId is: ', inputId);
      fetchEnrollCourseList(studentId);
    }
  }

  return (
    <FormItem
      label={label}
      {...Layout.formItemLayout}
      name='enrollment'
      rules={rules}
    >
      {
        <Select
          mode='single'
          onFocus={() => receiveEnrollCourse(inputId)}
          onChange={(e) => {
            form.setFieldsValue({ enrollment: e });
            if (props.setIsTrigger) props.setIsTrigger(!props.isTrigger);
            if (fetchEnrollCourseDetail) fetchEnrollCourseDetail(e);
          }}
        >
          {enrollCourseList.map((item, i) => {
            return (
              <Option value={item._id} key={item._id}>
                {item.courseName}
              </Option>
            );
          })}
        </Select>
      }
    </FormItem>
  );
};

export default EnrollCourseFormItem;
