import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tag } from 'antd';
import CourseObj from '../objects/courseType';
import RenderDelete from '../view/components/table/RenderDelete';

const renderTag = (courseType) => {
  const target = CourseObj.courseType.filter(
    (item) => item.type === courseType
  );
  return target[0] ? <Tag color={target[0].color}>{target[0].name}</Tag> : null;
};

const columns = [
  {
    title: '名字',
    dataIndex: 'studentName',
    key: 'studentName',
    render: (text, record, index) => {
      return <NavLink to={`/student/${record.student}`}>{text}</NavLink>;
    },
  },
  {
    title: '老师',
    dataIndex: 'teacherName',
    key: 'teacherName',
  },
  {
    title: '课程',
    dataIndex: 'courseName',
    key: 'courseName',
  },
  {
    title: '类型',
    dataIndex: 'courseType',
    key: 'courseType',
    render: (courseType) => renderTag(courseType),
  },
  {
    title: '日期',
    dataIndex: 'feedbackDate',
    key: 'feedbackDate',
    responsive: ['lg'],
  },
  {
    title: '内容',
    dataIndex: 'content',
    key: 'content',
    responsive: ['lg'],
  },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    width: 100,
    render: (record) => <RenderDelete record={record} />,
  },
];

export default { columns };
