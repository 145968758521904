import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import './style.less';

import StudentFormItem from '../form/StudentFormItem';

const Home = (props) => {
  const [form] = Form.useForm();
  const { studentList, fetchStudentList } = props;
  const history = useHistory();

  const handleSubmit = (e) => {
    // e.preventDefault();
    history.push(`/student/${form.getFieldValue().student_id}`);
  };

  return (
    <div className='home_search'>
      <div style={{ fontSize: 30 }}>PA Search</div>
      <Form layout='horizontal' onFinish={handleSubmit}>
        <StudentFormItem
          formItemLayout={{}}
          rules={[]}
          itemLabel={'NONE'}
          fetchStudentList={fetchStudentList}
          studentList={studentList}
          form={form}
        />
      </Form>
    </div>
  );
};

export default Home;
