import React from 'react';
import ReactDOM from 'react-dom';
import IRouter from './view/containers/Router';
import { Provider } from 'react-redux';
import store from './srcStore/';

ReactDOM.render(
  <Provider store={store}>
    <IRouter />
  </Provider>,
  document.getElementById('root')
);
