import React from 'react';
import { Form, Select, Input } from 'antd';
import Layout from './layout';
const FormItem = Form.Item;
const Option = Select.Option;

const defaultRules = [
  {
    required: true,
    message: '课程顾问',
  },
];

const AdvisorFormItem = (props) => {
  const { form, fetchAdvisorList, advisorList, itemStyle } = props;
  let layouts = props.formItemLayout
    ? props.formItemLayout
    : Layout.formItemLayout;
  let rules = props.rules ? props.rules : defaultRules;
  return (
    <FormItem
      label='课程顾问'
      {...layouts}
      name='course_advisor'
      rules={rules}
      form={form}
    >
      {
        <Select
          mode='single'
          onFocus={fetchAdvisorList}
          style={itemStyle}
          placeholder='顾问选择'
          allowClear
          onSelect={(v) => {
            console.log('auto complete: ', v);
            form.setFieldsValue({ course_advisor: v });
          }}
        >
          {advisorList.map((item, i) => {
            return (
              <Option value={item._id} key={item._id}>
                {item.cnname}
              </Option>
            );
          })}
        </Select>
      }
    </FormItem>
  );
};

export default AdvisorFormItem;
