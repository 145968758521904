import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'antd';

const User = (props) => {
  const { user, logout } = props;
  const history = useHistory();
  const [isVisible, setIsVisiable] = useState(false);

  function handleLogout() {
    logout();
    setIsVisiable(false);
    history.push('/logout/done');
  }

  return (
    <>
      <Button type='primary' size='small' onClick={() => setIsVisiable(true)}>
        {user}
      </Button>
      <Modal
        title='Logout'
        visible={isVisible}
        onOk={handleLogout}
        onCancel={() => setIsVisiable(false)}
      >
        <p>登出{user}?</p>
      </Modal>
    </>
  );
};

export default User;
