import React, { useEffect } from 'react';
import { Card, Form } from 'antd';
import Layout from './layout';
import dataTransform from '../../../util/dataTransform';
import StudentFormItem from './StudentFormItem';
import EnrollCourseFormItem from './EnrollCourseFormItem';
import CurrencyFormItem from './CurrencyFormItem';
import PaymentMethodFormItem from './PaymentMethodFormItem';
import UserListFormItem from './UserListFormItem';
import InputFormItem from './InputFormItem';
import ShowUserDetail from './ShowUserDetail';
import SubmitFormItem from './SubmitFormItem';
import DateFormItem from './DateFormItem';
import FormSteps from './FormSteps';

function handleSubmit(form, postEnrollTransaction, history) {
  const rawData = form.getFieldValue();
  console.log('raw data: ', rawData);
  const transactionData = dataTransform.transactionDataTransform(rawData);
  console.log('transactionData', transactionData);
  postEnrollTransaction(transactionData, history);
}

const Transaction = (props) => {
  const [form] = Form.useForm();

  const {
    history,
    fetchStudentList,
    studentList,
    fetchEnrollCourseList,
    enrollCourseList,
    userList,
    fetchUserList,
    postEnrollTransaction,
    fetchEnrollCourseDetail,
    enrollCourseDetail,
    searchDetail,
  } = props;

  /**
   * 清空无用的数据
   */
  useEffect(() => {
    return () => {
      console.log('leave page');
      searchDetail({});
    };
  }, []);

  return (
    <div>
      <FormSteps currentStep={2} />
      <Card title='请登记学生转账的信息' style={Layout.cardStyle}>
        <Form form={form}>
          <StudentFormItem
            rules={[]}
            form={form}
            fetchStudentList={fetchStudentList}
            studentList={studentList}
            itemStyle={Layout.filterInputStyle}
          />
          <EnrollCourseFormItem
            fetchEnrollCourseList={fetchEnrollCourseList}
            fetchEnrollCourseDetail={fetchEnrollCourseDetail}
            enrollCourseList={enrollCourseList}
            form={form}
          />
          <ShowUserDetail
            label='学生余款'
            displayDetail={enrollCourseDetail}
            displayData='balance'
            prefix='AUD'
          />
          <ShowUserDetail
            label='试听课老师'
            displayDetail={enrollCourseDetail}
            displayData='trialTeacher'
          />
          <ShowUserDetail
            label='课程顾问'
            displayDetail={enrollCourseDetail}
            displayData='advisor'
          />
          <InputFormItem
            label='本次支付金额'
            fieldName='total_paid'
            placeholder='请输入支付金额'
            requireMessage='支付金额不能为空'
          />
          <CurrencyFormItem />
          <PaymentMethodFormItem />
          <InputFormItem
            label='总计澳币金额'
            fieldName='total_aud'
            placeholder='请输支付折算成澳币的金额'
            requireMessage='总计澳币金额不能为空'
          />
          <UserListFormItem fetchUserList={fetchUserList} userList={userList} />
          <DateFormItem form={form} />
          <InputFormItem
            label='备注'
            fieldName='comments'
            placeholder='请输入备注'
          />
          <SubmitFormItem
            hasModel={false}
            handleClick={() =>
              handleSubmit(form, postEnrollTransaction, history)
            }
          />
        </Form>
      </Card>
    </div>
  );
};

export default Transaction;
